import React, { useEffect, useRef, useState } from 'react';
import {
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '@material-ui/core/CircularProgress';
import PreviewIcon from '../../assets/img/preview.svg';

import useStyles from './styles';
import CustomButton from '../CustomButton';
import { selectBuilderSettings } from '../../redux/selectors/builders';
import { builderSettingsRequest } from '../../redux/actions/builders';
import { BuilderMessaging } from '../../redux/types/builders';
import { replacePlaceholders } from '../../utils/helpers';
import CommunitySearch from "../../containers/CommunitySearch";
import PropertySearch from "../../containers/PropertySearch";

type Variables = {
  visitor_first_name: string;
  community_name: string;
  community_sales_phone_number: string;
  community_url: string;
  property_address: string;
  property_subdivision: string;
  property_marketing_name: string;
  property_city: string;
  property_state: string;
  property_lot_number: string;
  company_name: string,
  company_marketing_name: string,
  company_legal_name: string,
};

type Props = {
  builderId: number;
  builderName: string;
  isLoading: boolean;
  saveMessaging: (messages: BuilderMessaging) => void;
  loadCommunityProperties: (communityId: number) => void;
};

const Messaging: React.FC<Props> = (props) => {
  const {
    builderId,
    builderName,
    isLoading,
    saveMessaging,
    loadCommunityProperties
  } = props;

  const builderSettings = useSelector(selectBuilderSettings);
  const [isInitialized, setIsInitialized] = useState(false);
  const CHAR_LIMIT = 240;

  const groupHeaders = {
    "Visitor Variables": ["visitor_first_name"],
    "Community Variables": [
      "community_name",
      "community_sales_phone_number",
      "community_url",
    ],
    "Property Variables": [
      "property_address",
      "property_subdivision",
      "property_marketing_name",
      "property_city",
      "property_state",
      "property_lot_number",
    ],
    "Company Variables": [
      "company_name",
      "company_marketing_name",
      "company_legal_name",
    ],
  };

  const initialVariables: Variables = {
    visitor_first_name: 'John',
    community_name: '',
    community_sales_phone_number: '',
    community_url: '',
    property_address: '',
    property_subdivision: '',
    property_marketing_name: '',
    property_city: '',
    property_state: '',
    property_lot_number: '',
    company_name: builderName,
    company_marketing_name: builderSettings?.marketing_name ?? '',
    company_legal_name: builderSettings?.legal_name ?? '',
  };
  const defaultDuringTourMessage = `Hi ${'{visitor_first_name}'}. Welcome to ${builderName}'s ${'{community_name}'}. Enjoy your tour!`;
  const defaultAfterTourMessage = `Thank you for visiting ${builderName}'s home in {community_name}. We hope that you enjoyed your tour. If you have any questions or would like more information, please contact us at {community_sales_phone_number}`;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [filteredVariables, setFilteredVariables] = useState<string[]>([]);
  const [variables, setVariables] = useState<Variables>(initialVariables);
  const [duringTourMessage, setDuringTourMessage] = useState(
    defaultDuringTourMessage,
  );
  const [afterTourMessage, setAfterTourMessage] = useState(
    defaultAfterTourMessage,
  );
  const [duringTourPreview, setDuringTourPreview] = useState(
    replacePlaceholders(defaultDuringTourMessage, variables),
  );
  const [afterTourPreview, setAfterTourPreview] = useState(
    replacePlaceholders(defaultAfterTourMessage, variables),
  );
  const [isDuringTourMessageActive, setIsDuringTourMessageActive] = useState(
    false,
  );
  const [isAfterTourMessageActive, setIsAfterTourMessageActive] = useState(
    false,
  );

  const dispatch = useDispatch();
  const classes = useStyles();
  const afterTourInputRef = useRef(null);
  const duringTourInputRef = useRef(null);

  useEffect(() => {
    dispatch(builderSettingsRequest(builderId));
  }, [dispatch, builderId]);

  useEffect(() => {
    if (!isInitialized && builderSettings) {
      if (builderSettings.during_tour_message) {
        setIsDuringTourMessageActive(true);
        setDuringTourMessage(builderSettings.during_tour_message);
        setDuringTourPreview(replacePlaceholders(builderSettings.during_tour_message, variables));
      }

      if (builderSettings.after_tour_message) {
        setIsAfterTourMessageActive(true);
        setAfterTourMessage(builderSettings.after_tour_message);
        setAfterTourPreview(replacePlaceholders(builderSettings.after_tour_message, variables));
      }

      setIsInitialized(true);
    }
  }, [builderSettings, variables, isInitialized]);

  const handleMessageChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (value.includes('@')) {
      const lastAtSymbolIndex = value.lastIndexOf('@');
      const searchText = value.slice(lastAtSymbolIndex + 1).toLowerCase();
      // const filteredVars = dynamicVariables.filter((variable) => variable.toLowerCase().includes(searchText));
      const filteredVars = Object.keys(variables).filter((key) => key.toLowerCase().includes(searchText));

      switch (name) {
        case 'duringTourMessage':
          setAnchorEl(duringTourInputRef.current);
          break;

        case 'afterTourMessage':
          setAnchorEl(afterTourInputRef.current);
          break;

        default:
          break;
      }

      setOpen(true);
      // setFilteredVariables(
      //   filteredVars.length > 0 ? filteredVars : dynamicVariables,
      // );
      setFilteredVariables(
        filteredVars.length > 0
          ? filteredVars.map((key) => `{${key}}`)
          : Object.keys(variables).map((key) => `{${key}}`),
      );
    }
    if (name === 'duringTourMessage') {
      const preview = replacePlaceholders(value, variables);
      setDuringTourMessage(value);
      setDuringTourPreview(preview);
    }

    if (name === 'afterTourMessage') {
      const preview = replacePlaceholders(value, variables);
      setAfterTourMessage(value);
      setAfterTourPreview(preview);
    }
  };

  const handleVariableSelect = (
    variable: string,
    anchor: HTMLElement | null = null,
  ) => {
    const activeInput = anchor?.querySelector('textarea');
    const message = activeInput?.name === 'duringTourMessage'
      ? duringTourMessage
      : afterTourMessage;
    const lastAtSymbolIndex = message.lastIndexOf('@');
    const beforeCursor = message.slice(0, lastAtSymbolIndex);
    const afterCursor = message.slice(lastAtSymbolIndex).replace(/@/, '');
    const newMessage = `${beforeCursor}${variable}${afterCursor}`;

    if (activeInput?.name === 'duringTourMessage') {
      const preview = replacePlaceholders(newMessage, variables);
      setDuringTourMessage(newMessage);
      setDuringTourPreview(preview);
    }

    if (activeInput?.name === 'afterTourMessage') {
      const preview = replacePlaceholders(newMessage, variables);
      setAfterTourMessage(newMessage);
      setAfterTourPreview(preview);
    }

    setOpen(false);

    requestAnimationFrame(() => {
      if (activeInput) {
        activeInput.focus();
      }
    });
  };

  const countMessageCharacters = (message: string) => {
    const cleanedMessage = message.replace(/\{(.*?)\}/g, (match, key) => Object.prototype.hasOwnProperty.call(variables, key) ? '' : match);

    return cleanedMessage.length;
  };

  const handleChangeCommunity = (value: number, rest?: any) => {
    loadCommunityProperties(value);
    setVariables({
      ...variables,
      community_name: rest.label,
      community_url: rest.url,
      community_sales_phone_number: rest.sales_agent,
    });
  }

  const handleChangeProperty = (value: number, rest?: any) => {
    setVariables({
      ...variables,
      property_address: rest?.address,
      property_subdivision: rest?.subdivision,
      property_marketing_name: rest?.marketing_name,
      property_city: rest?.city,
      property_state: rest?.state,
      property_lot_number: rest.lot_number,
    });
  }

  useEffect(() => {
    if(duringTourPreview) {
      setDuringTourPreview(replacePlaceholders(duringTourMessage, variables));
    }
    if(afterTourPreview) {
      setAfterTourPreview(replacePlaceholders(afterTourMessage, variables));
    }
    // eslint-disable-next-line
  }, [variables]);

  const handleSave = async () => {
    try {
      const during_tour_message = isDuringTourMessageActive
        ? duringTourMessage
        : null;
      const after_tour_message = isAfterTourMessageActive
        ? afterTourMessage
        : null;

      saveMessaging({
        during_tour_message,
        after_tour_message,
      });
    } catch (errors) {
      console.log('errors:', errors);
    }
  };

  const closePopper = () => {
    if (open) {
      setOpen(false);
    }
  };

  const isSubmitEnabled = () => {
    const duringTourValidate = !isDuringTourMessageActive || (isDuringTourMessageActive && countMessageCharacters(duringTourMessage) < CHAR_LIMIT);
    const afterTourValidate = !isAfterTourMessageActive || (isAfterTourMessageActive && countMessageCharacters(afterTourMessage) < CHAR_LIMIT);
    return duringTourValidate && afterTourValidate;
  }

  return isLoading ? (
    <Grid container justify="center">
      <div className={classes.spinnerWrapper}>
        <Spinner />
      </div>
    </Grid>
  ) : (
    <>
      <Divider />
      <div className={classes.headerText}>
        <span>Follow Up Texts to Visitors</span>
      </div>
      <Grid container>
        <Grid item xs>
          <FormGroup>
            <Popper
              style={{ width: '40vw'}}
              open={open}
              anchorEl={anchorEl}
              placement="bottom-start"
            >
              <Paper style={{ maxHeight: '400px', overflow: 'auto' }}>
                <MenuList>
                  {filteredVariables.map((variable) => (
                    <MenuItem
                      key={variable}
                      onMouseDown={(e) => {
                        e.stopPropagation();
                        handleVariableSelect(variable, anchorEl);
                      }}
                    >
                      {variable}
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </Popper>
            <div className={classes.messageBox}>
              <div>
                <FormControlLabel
                  label={(
                    <>
                      <span style={{ fontWeight: 'bold' }}>During Tour: </span>
                      <span className={classes.subtitle}>
                      triggers 60 seconds after someone enters a code
                      into the lock
                      </span>
                    </>
                )}
                  control={(
                    <Switch
                      inputProps={{ style: { width: '40px' } }}
                      checked={isDuringTourMessageActive}
                      onChange={() => {
                        setIsDuringTourMessageActive(!isDuringTourMessageActive);
                      }}
                      name="duringTour"
                    />
                  )}
                />
              </div>
              <div
                className={`${classes.textArea} ${!isDuringTourMessageActive
                && classes.disabled}`}
                style={{marginBottom: '40px'}}
              >
                <TextField
                  ref={duringTourInputRef}
                  fullWidth
                  multiline
                  variant="outlined"
                  // className={`${classes.textArea} ${!isDuringTourMessageActive
                  //   && classes.disabled}`}
                  margin="normal"
                  id="duringTourMessage"
                  name="duringTourMessage"
                  value={duringTourMessage}
                  onChange={handleMessageChange}
                  onBlur={closePopper}
                  inputProps={{
                    style: {
                      padding: '0 12px',
                    },
                  }}
                />
                <div className={classes.textLimit}
                     style={{
                       color: countMessageCharacters(duringTourMessage) > CHAR_LIMIT ? 'red' : '#6B7280'
                     }}
                >
                  {countMessageCharacters(duringTourMessage)}/{CHAR_LIMIT} characters
                </div>
                <div className={classes.previewBox}>
                  <div className={classes.previewWithIcon}>
                    <img src={PreviewIcon} alt="Preview Icon" className={classes.previewIcon}/>
                    <Typography className={classes.previewTitle}>Preview:</Typography>
                  </div>
                  <Typography> {duringTourPreview} </Typography>
                </div>
              </div>
            </div>

            <div className={classes.messageBox}>
              <div>
                <FormControlLabel
                  label={(
                    <>
                      <span style={{fontWeight: 'bold'}}>After Tour: </span>
                      <span className={classes.subtitle}>
                        triggers 30 minutes after they tour
                      </span>
                    </>
                  )}
                  control={(
                    <Switch
                      checked={isAfterTourMessageActive}
                      onChange={() => {
                        setIsAfterTourMessageActive(!isAfterTourMessageActive);
                      }}
                      name="afterTour"
                    />
                  )}
                />
              </div>
              <div className={`${classes.textArea} ${!isAfterTourMessageActive
              && classes.disabled}`}
              >
                <TextField
                  fullWidth
                  multiline
                  ref={afterTourInputRef}
                  variant="outlined"
                  margin="normal"
                  id="afterTourMessage"
                  name="afterTourMessage"
                  value={afterTourMessage}
                  onChange={handleMessageChange}
                  onBlur={closePopper}
                  inputProps={{
                    style: {
                      padding: '0 12px',
                    },
                  }}
                />
                <div
                 className={classes.textLimit}
                 style={{
                   color: countMessageCharacters(afterTourMessage) > CHAR_LIMIT ? 'red' : '#6B7280'
                 }}
                >
                  {countMessageCharacters(afterTourMessage)}/{CHAR_LIMIT} characters
                </div>
                <div className={classes.previewBox}>
                  <div className={classes.previewWithIcon}>
                    <img src={PreviewIcon} alt="Preview Icon" className={classes.previewIcon}/>
                    <Typography className={classes.previewTitle}>Preview:</Typography>
                  </div>
                  <Typography> {afterTourPreview} </Typography>
                </div>
              </div>
            </div>

          </FormGroup>
        </Grid>
        <Grid item xs>
          <Table className={classes.dropdownTable} aria-label="customized table">
            <TableBody>
              <TableRow className={classes.tableRow}>
                <TableCell align="left" className={classes.tableCell}>
                  <h3 style={{marginBottom: '20px'}}>Select Community Preview</h3>
                  <CommunitySearch
                    handleChange={handleChangeCommunity}
                    builderId={builderId}
                  />
                  <h3 style={{marginBottom: '20px', marginTop: '10px'}}>Select Property Preview</h3>
                  <PropertySearch
                    handleChange={handleChangeProperty}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table className={classes.table} aria-label="customized table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell style={{ width: '200px' }}>
                  Dynamic Variables
                </TableCell>
                <TableCell className={classes.tableCell} />
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(groupHeaders).map(([groupName, keys]) => (
                <React.Fragment key={groupName}>
                  <TableRow className={classes.tableRow}>
                    <TableCell
                      colSpan={2}
                      align="left"
                      className={classes.tableCell}
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      {groupName}
                    </TableCell>
                  </TableRow>
                  {keys.map((key) => {
                    const variableKey = key as keyof Variables;

                    return (
                      <TableRow className={classes.tableRow} key={key}>
                        <TableCell align="left" className={classes.tableCell}>
                          {`{${key}}`}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {variables[variableKey]}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      {/*<Divider style={{ margin: '20px 0 20px' }} />*/}
      <div className={classes.navButtons}>
        <CustomButton className={classes.submitButtons} variant="orange" onClick={() => handleSave()} disabled={!isSubmitEnabled()}>
          Submit
        </CustomButton>
      </div>
    </>
  );
};

export default React.memo(Messaging);
