import React, { useMemo, useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import useStyles from '../styles';
import CustomButton from '../../../components/CustomButton';
import { Invoice } from '../../../redux/types/billingQuickbook';
import { loadInvoice } from '../../../services/billingQuickbook';

interface Props {
  invoice: Invoice;
  formatCurrency: (amount: number) => string;
}

const BillingQuickbookRow: React.FC<Props> = (props) => {
  const {
    invoice,
    formatCurrency,
  } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  // Memoize formatDate function
  const formatDate = useMemo(() => (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }).format(date);
  }, []);

  // Memoize getStatusColor function
  const getStatusColor = useMemo(() => (status: string) => {
    switch (status.toLowerCase()) {
      case 'paid':
        return '#4CAF50'; // Green
      case 'overdue':
        return '#F44336'; // Red
      case 'pending':
        return '#FF9800'; // Orange
      default:
        return '#6B7280'; // Gray
    }
  }, []);

  const loadInvoiceDetail = async () => {
    await loadInvoice(invoice.id).then((resp: any) => {
      if (resp.data && resp.status === 200 && resp.data.invoiceLink) {
        window.open(resp.data.invoiceLink, '_blank');// Open the invoice link in a new tab
      } else {
        console.error('Invoice link not available');
      }
    });
  };

  return (
    <>
      <TableRow key={invoice.id} className={classes.tableRow}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{invoice.docNumber}</TableCell>
        <TableCell>{formatDate(invoice.dueDate)}</TableCell>
        <TableCell>{formatDate(invoice.dueDate)}</TableCell>
        <TableCell>{formatCurrency(invoice.totalAmount)}</TableCell>
        <TableCell>
          <span
            className={classes.statusBadge}
            style={{ backgroundColor: getStatusColor(invoice.status) }}
          >
            {invoice.status}
          </span>
        </TableCell>
        <TableCell>
          <CustomButton
            size="small"
            variant="orange"
            className={classes.viewButton}
            onClick={loadInvoiceDetail}
            style={{ visibility: invoice.status.toLowerCase() === 'overdue' ? 'visible' : 'hidden' }}
          >
            Pay
          </CustomButton>
          <CustomButton
            size="small"
            variant="white"
            className={classes.viewButton}
            onClick={loadInvoiceDetail}
          >
            View
          </CustomButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Line Items
              </Typography>
              <Table size="small" aria-label="line items">
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell>Item</TableCell>
                    <TableCell align="center">Quantity</TableCell>
                    <TableCell align="center">Unit Price</TableCell>
                    <TableCell align="center">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice.salesLineItems && invoice.salesLineItems.map((lineItem, index) => (
                    <TableRow key={`${invoice.id}-${index}`}>
                      <TableCell component="th" scope="row">
                        {lineItem.description}
                      </TableCell>
                      <TableCell>{lineItem.itemName}</TableCell>
                      <TableCell align="center">{lineItem.quantity}</TableCell>
                      <TableCell align="center">{formatCurrency(lineItem.unitPrice)}</TableCell>
                      <TableCell align="center">{formatCurrency(lineItem.amount)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default React.memo(BillingQuickbookRow);
