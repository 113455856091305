import { ErrorMessage, ValueOf } from '../../types/main';
import * as BuildersActions from '../actions/builders';
import { IntegrationsInfo } from './properties';

export const SAVE_BUILDER_SETTINGS = 'SAVE_BUILDER_SETTINGS';
export const BUILDERS_SETTINGS_REQUEST = 'BUILDERS_SETTINGS_REQUEST';
export const BUILDERS_SETTINGS_REQUEST_FAILED = 'BUILDERS_SETTINGS_REQUEST_FAILED';
export const BUILDERS_SETTINGS_REQUEST_SUCCESS = 'BUILDERS_SETTINGS_REQUEST_SUCCESS';
export const BUILDERS_REQUEST_SUCCESS = 'BUILDERS_REQUEST_SUCCESS';
export const BUILDERS_REQUEST_FAILED = 'BUILDERS_REQUEST_FAILED';
export const BUILDERS_REQUEST = 'BUILDERS_REQUEST';
export const SAVE_BUILDER_SETTINGS_FAILED = 'SAVE_BUILDER_SETTINGS_FAILED';
export const SAVE_BUILDER_SETTINGS_SUCCESS = 'SAVE_BUILDER_SETTINGS_SUCCESS';
export const SAVE_BUILDER_DISCLOSURES = 'SAVE_BUILDER_DISCLOSURES';
export const SAVE_BUILDER_DISCLOSURES_FAILED = 'SAVE_BUILDER_DISCLOSURES_FAILED';
export const SAVE_BUILDER_DISCLOSURES_SUCCESS = 'SAVE_BUILDER_DISCLOSURES_SUCCESS';
export const SAVE_BUILDER_MESSAGING = 'SAVE_BUILDER_MESSAGING';
export const SAVE_BUILDER_MESSAGING_FAILED = 'SAVE_BUILDER_MESSAGING_FAILED';
export const SAVE_BUILDER_MESSAGING_SUCCESS = 'SAVE_BUILDER_MESSAGING_SUCCESS';
export const BUILDERS_PMS_REQUEST = 'BUILDERS_PMS_REQUEST';
export const BUILDERS_PMS_REQUEST_FAILED = 'BUILDERS_PMS_REQUEST_FAILED';
export const BUILDERS_PMS_REQUEST_SUCCESS = 'BUILDERS_PMS_REQUEST_SUCCESS';
export const ENTERPRISE_BUILDERS_REQUEST = 'ENTERPRISE_BUILDERS_REQUEST';
export const ENTERPRISE_BUILDERS_REQUEST_SUCCESS = 'ENTERPRISE_BUILDERS_REQUEST_SUCCESS';
export const ENTERPRISE_BUILDERS_REQUEST_FAILED = 'ENTERPRISE_BUILDERS_REQUEST_FAILED';
export const ENTERPRISE_USER_BUILDERS_REQUEST = 'ENTERPRISE_USER_BUILDERS_REQUEST';
export const ENTERPRISE_USER_BUILDERS_REQUEST_SUCCESS = 'ENTERPRISE_USER_BUILDERS_REQUEST_SUCCESS';
export const ENTERPRISE_USER_BUILDERS_REQUEST_FAILED = 'ENTERPRISE_USER_BUILDERS_REQUEST_FAILED';
export const BUILDER_INTEGRATIONS_REQUEST = 'BUILDER_INTEGRATIONS_REQUEST';
export const BUILDER_INTEGRATIONS_SUCCESS = 'BUILDER_INTEGRATIONS_SUCCESS';
export const BUILDER_INTEGRATIONS_FAILED = 'BUILDER_INTEGRATIONS_FAILED';
export const BUILDER_TIMEZONE_REQUEST = 'BUILDER_TIMEZONE_REQUEST';
export const BUILDER_TIMEZONE_SUCCESS = 'BUILDER_TIMEZONE_SUCCESS';
export const BUILDER_TIMEZONE_FAILED = 'BUILDER_TIMEZONE_FAILED';
export const BUILDER_TIMEZONE_UPDATE_REQUEST = 'BUILDER_TIMEZONE_UPDATE_REQUEST';
export const BUILDER_TIMEZONE_UPDATE_SUCCESS = 'BUILDER_TIMEZONE_UPDATE_SUCCESS';
export const BUILDER_TIMEZONE_UPDATE_FAILED = 'BUILDER_TIMEZONE_UPDATE_FAILED';
export const BUILDER_FEED_SETTINGS_REQUEST = 'BUILDER_FEED_SETTINGS_REQUEST';
export const BUILDER_FEED_SETTINGS_REQUEST_FAILED = 'BUILDER_FEED_SETTINGS_REQUEST_FAILED';
export const BUILDER_FEED_SETTINGS_REQUEST_SUCCESS = 'BUILDER_FEED_SETTINGS_REQUEST_SUCCESS';
export const BUILDER_FEED_SETTINGS_UPDATE = 'BUILDER_FEED_SETTINGS_UPDATE';
export const BUILDER_FEED_SETTINGS_UPDATE_SUCCESS = 'BUILDER_FEED_SETTINGS_UPDATE_SUCCESS';
export const BUILDER_FEED_SETTINGS_UPDATE_FAILED = 'BUILDER_FEED_SETTINGS_UPDATE_FAILED';
export const SAVE_BUILDER_BILLING = 'SAVE_BUILDER_BILLING';
export const SAVE_BUILDER_BILLING_FAILED = 'SAVE_BUILDER_BILLING_FAILED';
export const SAVE_BUILDER_BILLING_SUCCESS = 'SAVE_BUILDER_BILLING_SUCCESS';

export type BuildersActionsTypes = ReturnType<ValueOf<typeof BuildersActions>>;

export interface Builder {
  name: string;
  builder_id: number;
}

export interface BuilderSettings {
  builderName: string | null
  primary_color: string
  secondary_color: string
  country: string
  logo?: string
  file?: File
  button_settings: string
  content_settings?: string;
  selfie_verification_active: boolean
  legal_name?: string;
  marketing_name?: string;
  webflow_name?: string;
  custom_disclosure_active?: boolean;
  custom_disclosure_name?: string | null;
  custom_disclosure_text?: string | null;
  during_tour_message?: string | null;
  after_tour_message?: string | null;
}

export interface BuilderMessaging {
  during_tour_message: string | null;
  after_tour_message: string | null;
}

export interface BuilderBilling {
  invoice_detail: string | null;
  invoice_view: string | null;
}

export interface BuilderContentSettingsRequest {
  self_tour_scheduling_title: string;
  self_tour_scheduling_subtitle: string;
  self_tour_scheduling_description: string;
  how_it_works_title: string;
  step_1_title: string;
  step_1_description: string;
  step_2_title: string;
  step_2_description: string;
  step_3_title: string;
  step_3_description: string;
  step_4_title: string;
  step_4_description: string;
  start_touring_button: string;
  self_tours_own_time_title: string;
  tour_now_title: string;
  tour_now_description: string;
  tour_now_button: string;
  schedule_tour_title: string;
  schedule_tour_description: string;
  schedule_tour_button: string;
}

export const CONTENT_SETTINGS_DEFAULT: BuilderContentSettingsRequest = {
  self_tour_scheduling_title: 'Self-tour scheduling',
  self_tour_scheduling_subtitle: 'made easy!',
  self_tour_scheduling_description: 'Finding your dream home is just a few steps away. Enjoy a private tour of our beautiful new homes at a time that is convenient for you.',
  how_it_works_title: 'How it works',
  step_1_title: 'Select the home',
  step_1_description: 'Search our completed homes to see which offer self-guided tours.',
  step_2_title: 'Verify identity',
  step_2_description: 'Create an account with a valid ID and phone number.',
  step_3_title: 'Choose the time',
  step_3_description: 'Select ‘Tour Now’ or schedule for a later time.',
  step_4_title: 'Receive access code',
  step_4_description: 'At the selected time, receive an access code and enjoy your tour.',
  start_touring_button: 'Start Touring',
  self_tours_own_time_title: 'Self-Tours on own time',
  tour_now_title: 'Tour Now! Get On-Demand Access',
  tour_now_description: 'Once you are at the property, verify your identity, get a one-time use access code and enjoy your home tour!',
  tour_now_button: 'Tour Now',
  schedule_tour_title: 'Not at the property? Schedule a Tour!',
  schedule_tour_description: 'Pick a community and check out the properties open for touring. Book a self-guided tour for a later date!',
  schedule_tour_button: 'Schedule a Tour',
};


export interface BuilderFeedSettings {
  builderId?: number
  company_code?: string
  add_communities_active?: boolean
  feed_source?: string
  feed_url?: string
  feed_username?: string
  feed_password?: string
  feed_fields?: string[]
}

export interface BuilderPreviewSizes {
  desktopWidth: number,
  desktopHeight: number,
  mobileWidth: number,
  mobileHeight: number,
}

export interface BuilderPM {
  username: string;
  id: number;
  groups: {
    GroupID: number,
    Label: string
  }[];
  __agent__?: any;
}

export interface BuildersState {
  items: Builder[];
  loading: boolean;
  error: ErrorMessage['message'];
  selected: Builder | null;
  integrations: IntegrationsInfo | null;
  builderTimezone: number | null;
  feedSettings: BuilderFeedSettings | null;
}

export interface BuildersSettingsState {
  item: BuilderSettings | null;
  loading: boolean;
  error: ErrorMessage['message'];
}

export interface BuildersPMsState {
  items: BuilderPM[];
  loading: boolean;
  error: ErrorMessage['message'];
  selected: BuilderPM | null;
}

export type BuildersRequestSuccess = Builder[];
export type BuildersPMsRequestSuccess = BuilderPM[];
