import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
  Typography, CardMedia, WithStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ezlohub from '../../assets/img/ezlohub.jpg';
import adchubImg from '../../assets/img/adc_hub.png';
import kwiksetImg from '../../assets/img/kwikset.png';
import smartLockImg from '../../assets/img/smart_lock.png';
import styles from './styles';
import { Hub } from '../../redux/types/hubs';

type Props = {
  hub: Hub;
} & WithStyles<typeof styles>;


const HubWidget: React.FC<Props> = (props) => {
  const { classes, hub } = props;
  let image = '';
  let hubType = hub.hub_type;
  switch (hub.hub_type.toLowerCase()) {
    case 'kwikset':
      image = kwiksetImg;
      break;
    case 'adc':
      image = adchubImg;
      break;
    case 'seam':
      image = smartLockImg;
      hubType = "Wifi Lock";
      break;
    default:
      image= ezlohub;
      break;
  }

  return (
    <Card
      role="presentation"
      className={classes.card}
      // onClick={() => navTo(`/inventory/${device.serial_number}`)}
    >
      <div className={classes.iconWrapper}>
        <CardMedia
          className={classes.icon}
          image={image}
        />
      </div>
      <CardContent>
        <Typography className={classes.primaryText}>
          Hub ID:
          &nbsp;
          <span className="hubId">{hub.serial_number}</span>
        </Typography>
        <Typography className={classes.secondaryText}>
          Hub type:
          &nbsp;
          <span className={classes.secondaryText}>{hubType}</span>
        </Typography>
        <Typography className={classes.secondaryText}>
          Property ID:
          &nbsp;
          <Link className={classes.link} onClick={(e) => e.stopPropagation()} to={`/properties/${hub.property_id}`}>{hub.property_id}</Link>

        </Typography>
      </CardContent>
    </Card>
  );
};

export default React.memo(HubWidget);
