import React from 'react';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { WithStyles } from '@material-ui/core';
import styles from './styles';
import { CustomerVisits } from '../../redux/types/customers';
import { formatToESTDate } from '../../utils/date';

import { Link } from 'react-router-dom';

type Props = {
  activity: CustomerVisits;
} & WithStyles<typeof styles>;

const ActivityFeedItem: React.FC<Props> = (props) => {
  const { classes, activity } = props;
  const propertyAgents = activity.agents.map((agent) => {
    const prefix = agent.is_project_manager ? 'PM' : 'AG';

    return `${prefix}: ${agent.firstname} ${agent.lastname}`;
  });
  const propertyAddress = activity.address_1;
  const [formatedDate, time] = formatToESTDate(activity.modified_on, activity.propertyTimezone, activity.propTimezoneValue).split(',');

  return (
    <div className={classes.container}>
      <div className={classes.circle} />
      <div className={classnames(classes.drawLineStyles, classes.content)}>
        <Grid container>
          <Grid item xs={12} sm={2}>
            <Typography className={classes.activityDate}>
              {formatedDate}
            </Typography>
            <Typography className={classes.activityDate}>
              {time}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography color="textSecondary">Visit status</Typography>
            <Typography>{activity.visit_status_id_value}</Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography color="textSecondary">Attribution</Typography>
            <Typography>{activity.attribution}</Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography color="textSecondary">Address</Typography>
            <Link to={`/properties/${activity.property_id}`}>
              <Typography>{propertyAddress}</Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography color="textSecondary">Lot #</Typography>
            <Typography>{activity.house_num}</Typography>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography color="textSecondary">Client</Typography>
            <Typography>{activity.builder}</Typography>
          </Grid>   
          <Grid item xs={12} sm={3}>
            <Typography color="textSecondary">Agent</Typography>
            <Typography>{propertyAgents.join(', ')}</Typography>
          </Grid>
        </Grid>
        <Typography className={classes.comment}>{activity.remarks}</Typography>
      </div>
      <div className={classnames(classes.drawLineStyles, classes.line)} />
    </div>
  );
};

export default React.memo(ActivityFeedItem);
