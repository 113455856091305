import {
  BILLING_QUICKBOOK_CUSTOMER_FAILED,
  BILLING_QUICKBOOK_CUSTOMER_INVOICES_FAILED,
  BILLING_QUICKBOOK_CUSTOMER_INVOICES_REQUEST,
  BILLING_QUICKBOOK_CUSTOMER_INVOICES_SUCCESS,
  BILLING_QUICKBOOK_CUSTOMER_REQUEST,
  BILLING_QUICKBOOK_CUSTOMER_SUCCESS,
  BILLING_QUICKBOOK_INVOICE_FAILED,
  BILLING_QUICKBOOK_INVOICE_REQUEST,
  BILLING_QUICKBOOK_INVOICE_SUCCESS,
  BillingQuickbookActionsTypes,
  BillingQuickbookState,
} from '../types/billingQuickbook';

const initialState: BillingQuickbookState = {
  error: '',
  loading: false,
  customer: null,
  invoice: null,
  customerInvoices: [],
};

export default function BillingQuickbookReducer(
  state = initialState,
  action: BillingQuickbookActionsTypes,
): BillingQuickbookState {
  switch (action.type) {
    case BILLING_QUICKBOOK_INVOICE_REQUEST:
    case BILLING_QUICKBOOK_CUSTOMER_REQUEST:
    case BILLING_QUICKBOOK_CUSTOMER_INVOICES_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
      };

    case BILLING_QUICKBOOK_INVOICE_FAILED:
    case BILLING_QUICKBOOK_CUSTOMER_FAILED:
    case BILLING_QUICKBOOK_CUSTOMER_INVOICES_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };

    case BILLING_QUICKBOOK_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        invoice: action.payload,
      };

    case BILLING_QUICKBOOK_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        customer: action.payload,
      };

    case BILLING_QUICKBOOK_CUSTOMER_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        customerInvoices: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
