import React from 'react';
import { SingleSelect } from 'react-select-material-ui';
import { generateLabel } from '../../utils/helpers';
import useStyles from './styles';
import { Property } from '../../redux/types/properties';

type Props = {
  properties: Property[];
  handleChange: (value: number, rest?: any) => void;
};

const PropertySearch: React.FC<Props> = ({
  properties, handleChange,
}) => {
  const classes = useStyles();

  return (
    <SingleSelect
      className={classes.root}
      label={generateLabel('Property')}
      options={properties.map((property: {
        property_id: number,
        address_1: string,
        subdivision?: string,
        city?: string,
        state?: string,
        house_num?: string,
        zipcode?: string,
      }) => ({
        value: property.property_id,
        label: property.address_1,
        address: property.address_1,
        subdivision: property.subdivision,
        city: property.city,
        state: property.state,
        lot_number: property.house_num,
      }))}
      onChange={handleChange}
      // value={initialValue}
    />
  );
};

export default PropertySearch;
