import {
  BILLING_QUICKBOOK_CUSTOMER_FAILED,
  BILLING_QUICKBOOK_CUSTOMER_INVOICES_FAILED,
  BILLING_QUICKBOOK_CUSTOMER_INVOICES_REQUEST,
  BILLING_QUICKBOOK_CUSTOMER_INVOICES_SUCCESS,
  BILLING_QUICKBOOK_CUSTOMER_REQUEST,
  BILLING_QUICKBOOK_CUSTOMER_SUCCESS,
  BILLING_QUICKBOOK_INVOICE_FAILED,
  BILLING_QUICKBOOK_INVOICE_REQUEST,
  BILLING_QUICKBOOK_INVOICE_SUCCESS,
  BillingQuickbookCustomerInvoicesResponse,
  BillingQuickbookCustomerResponse,
  BillingQuickbookInvoiceDetailResponse,
} from '../types/billingQuickbook';
import { ErrorMessage } from '../../types/main';

export const billingQuickbookInvoiceRequest = (payload: string) => ({
  type: BILLING_QUICKBOOK_INVOICE_REQUEST,
  payload,
}as const);

export const billingQuickbookInvoiceFailed = (payload: ErrorMessage) => ({
  type: BILLING_QUICKBOOK_INVOICE_FAILED,
  payload,
}as const);

export const billingQuickbookInvoiceSuccess = (payload: BillingQuickbookInvoiceDetailResponse) => ({
  type: BILLING_QUICKBOOK_INVOICE_SUCCESS,
  payload,
}as const);

export const billingQuickbookCustomerRequest = (payload: string) => ({
  type: BILLING_QUICKBOOK_CUSTOMER_REQUEST,
  payload,
}as const);

export const billingQuickbookCustomerFailed = (payload: ErrorMessage) => ({
  type: BILLING_QUICKBOOK_CUSTOMER_FAILED,
  payload,
}as const);

export const billingQuickbookCustomerSuccess = (payload: BillingQuickbookCustomerResponse) => ({
  type: BILLING_QUICKBOOK_CUSTOMER_SUCCESS,
  payload,
}as const);

export const billingQuickbookCustomerInvoicesRequest = (payload: string) => ({
  type: BILLING_QUICKBOOK_CUSTOMER_INVOICES_REQUEST,
  payload,
}as const);

export const billingQuickbookCustomerInvoicesFailed = (payload: ErrorMessage) => ({
  type: BILLING_QUICKBOOK_CUSTOMER_INVOICES_FAILED,
  payload,
}as const);

export const billingQuickbookCustomerInvoicesSuccess = (
  payload: BillingQuickbookCustomerInvoicesResponse,
) => ({
  type: BILLING_QUICKBOOK_CUSTOMER_INVOICES_SUCCESS,
  payload,
}as const);
