/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Spinner from '@material-ui/core/CircularProgress';
import TableCell from '@material-ui/core/TableCell';
import { RouteComponentProps } from 'react-router-dom';
import TablePagination from '../../components/CustomTablePagination';
import SearchBar from '../SearchBar';
import Table from '../../components/CustomTable';
import { formatDate } from '../../utils/date';
import useProperties from './hooks/useProperties.hook';

import useStyles from './styles';
import { Property } from '../../redux/types/properties';
import { HeaderRowConfig } from '../../components/CustomTable/CustomTable';
import { Query } from '../../utils/query';
import { Order } from '../../utils/table';
import { ROWS_PER_PAGE_OPTIONS } from '../../constants/table';
import { useGetBuilderDetail } from '../../hooks/useGetBuilderDetail';

type Props = {
  properties: Property[];
  propertiesTotal: number;
  loading: boolean;
  builderId: number;
  loadProperties: (query: Query) => void;
  exportProperties: (selection: Array<number | string>) => void;
  printProperties: (selection: Array<number | string>) => void;
  exportAllProperties: (path: string) => void;
  clearFilterState: () => void;
  exportAllLoading: boolean;
} & RouteComponentProps;

const searchBarPlaceholder: string = 'Search all properties';
const statusColor: Record<string, string> = {
  active: '#04A777',
  down: '#D72638',
};

const Properties: React.FC<Props> = (props) => {
  const {
    properties,
    propertiesTotal,
    loading,
    loadProperties,
    exportProperties,
    printProperties,
    history,
    match,
    exportAllProperties,
    exportAllLoading,
    clearFilterState,
    builderId,
  } = props;
  const { data: builderDetail } = useGetBuilderDetail(builderId);
  const [selected, setSelected] = useState<Property['property_id'][]>([]);
  const [isShowAll, setIsShowAll] = useState(false);
  const [headRows, setHeadRows] = useState<HeaderRowConfig<Property>[]>([]);

  const selectedLength = selected.length;
  const classes = useStyles();
  const {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    dispatchProperty,
    searchRequest,
    setShowAll,
  } = useProperties(loadProperties);

  useEffect(() => {
    setSelected([]);
  }, [setSelected, page, rowsPerPage]);

  useEffect(() => {
    if (!properties.length) {
      return;
    }

    const hasModel = properties.some((property) => property.isModel);

    const defaultHeadRows: HeaderRowConfig<Property>[] = [
      {
        key: 'property_id',
        align: 'right',
        disablePadding: false,
        label: 'Property ID',
      },
      {
        key: 'community',
        align: 'right',
        disablePadding: false,
        label: 'Community',
      },
    ];
    const hasLocks = properties.some((property) => property.lock_serial_number);
    const hasSmartLocks = properties.some((property) => property.smart_lock_serial_number?.length);
    const hasHubs = properties.some((property) => property.hub_id);
    const hasMarketingName = properties.some((property) => property.marketing_name);


    if (hasModel) {
      defaultHeadRows.unshift({
        key: 'isModel',
        align: 'right',
        disablePadding: false,
        label: 'Model',
        prepareData:  ({ isModel }) => isModel ? 'Yes' : '',
      });
    }

    if (hasLocks) {
      defaultHeadRows.push({
        key: 'lock_serial_number',
        align: 'right',
        disablePadding: false,
        label: 'Lock #',
      });
    }

    if (hasSmartLocks) {
      defaultHeadRows.push({
        key: 'smart_lock_serial_number',
        align: 'right',
        disablePadding: false,
        label: 'Smart Lock #',
      });
    }

    if (hasHubs) {
      defaultHeadRows.push({
        key: 'hub_id',
        align: 'right',
        disablePadding: false,
        label: 'Hub ID',
      });
    }

    if (hasLocks || hasSmartLocks) {
      defaultHeadRows.push({
        key: 'lockStatus',
        align: 'right',
        disablePadding: true,
        label: 'Status',
        render: (property) => {
          if (property.propStatus !== 'Accessible') {
            return <TableCell align="right" />;
          }
          let cellStyles;
          if (property.lockStatus) {
            cellStyles = {
              color: statusColor[property.lockStatus.toLowerCase()] || 'inherit',
            };
          }
          if (property.smartLockStatus) {
            cellStyles = {
              color: statusColor[property.smartLockStatus.toLowerCase()] || 'inherit',
            };
          }
          return (
            <TableCell style={cellStyles} align="right">
              {property.lockStatus && property.lockStatus}
              {property.smartLockStatus && property.smartLockStatus}
            </TableCell>
          );
        },
      });
    }

    defaultHeadRows.push(
      {
        key: 'propStatus',
        align: 'right',
        disablePadding: true,
        label: 'Property Disposition',
      },
      {
        key: 'house_num',
        align: 'right',
        disablePadding: true,
        label: 'Lot #',
      });

    if (hasMarketingName) {
      defaultHeadRows.push({
        key: 'marketing_name',
        align: 'right',
        disablePadding: false,
        label: 'Marketing Name',
      });
    }

    defaultHeadRows.push(
      {
        key: 'address_1',
        align: 'right',
        disablePadding: false,
        label: 'Address',
      },
      {
        key: 'lock_assigned',
        align: 'right',
        disablePadding: false,
        label: 'Install Date',
        prepareData: ({ lock_assigned }) => formatDate(lock_assigned),
      },
    );

    setHeadRows(defaultHeadRows);
  }, [properties]);

  useEffect(
    () => () => {
      const pageNameIndex = 1;
      const pathElements = history.location.pathname.split('/');
      const pathname = pathElements[pageNameIndex];
      if (pathElements.length === 3) {
        return;
      }
      if (pathname !== 'properties') {
        dispatchProperty('RESET_STATE');
      }
    },
    [dispatchProperty, history.location.pathname],
  );

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      clearFilterState();
    };
  }, [clearFilterState]);

  function handleChangeOrder(newOrder: Order, newOrderBy: keyof Property) {
    setOrder(newOrder);
    setPage(0);
    setOrderBy(newOrderBy);
    dispatchProperty('CHANGE_PROPERTY', {
      currentOrder: newOrder,
      currentOrderBy: newOrderBy,
      currentPage: 0,
    });
  }

  function handleChangePage(pageNumber: number) {
    setPage(pageNumber);
    dispatchProperty('CHANGE_PROPERTY', {
      currentPage: pageNumber,
    });
  }

  function handleChangeRowsPerPage(rowsPerPageAmount: number) {
    setRowsPerPage(rowsPerPageAmount);
    setPage(0);
    dispatchProperty('CHANGE_PROPERTY', {
      rowsPerPageAmount,
      currentPage: 0,
    });
  }

  function handleSelect(newSelected: any[]) {
    setSelected(newSelected);
  }

  function handlePropertySelect(property: Property) {
    history.push(`${match.url}/${property.property_id}`);
  }

  function handleExport() {
    if (selectedLength !== 0) {
      exportProperties(selected);
    }
  }

  function handlePrint() {
    if (selectedLength !== 0) {
      printProperties(selected);
    }
  }

  const handleSearch = (value: string): void => {
    // saveLocalState({ search: value });
    setPage(0);
    setSearch(value);
    dispatchProperty('CHANGE_PROPERTY', {
      contextSearch: value,
      currentPage: 0,
    });
  };

  const exportAll = () => {
    exportAllProperties(history.location.pathname.split('/')[1]);
  };

  const handleShowAll = () => {
    setIsShowAll(!isShowAll);
    setShowAll(!isShowAll);
    setSelected([]);
  };

  const disableAddProperty = builderDetail?.disable_add_property;

  return (
    <>
      <Grid container>
        <Grid item className={classes.controlButtonPlace}>
          <Button
            variant="contained"
            disabled={disableAddProperty}
            className={classes.controlButton}
            onClick={() => history.push(`${match.path}/add`)}
          >
            Add property
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <SearchBar
            // listName={PROPERTIES}
            placeholder={searchBarPlaceholder}
            onChange={handleSearch}
            defaultValue={search}
            searchRequest={searchRequest}
          />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <Toolbar>
          <div>
            <Typography variant="h5">
              Properties
              {selectedLength > 0 ? ` ${selectedLength} selected.` : ''}
            </Typography>
          </div>
          <div className={classes.spacer} />
          <Button
            style={{ marginRight: 20 }}
            id="show-active-communities"
            size="small"
            onClick={handleShowAll}
          >
            {`${isShowAll ? 'Hide' : 'Show'} All`}
          </Button>
          {selectedLength > 0 && (
            <div className={classes.toolbarActions}>
              <span>Selected actions: </span>
              <Button
                id="fs-export-property"
                size="small"
                disabled={selectedLength === 0}
                onClick={handleExport}
              >
                Export
              </Button>
              <Button
                size="small"
                disabled={selectedLength === 0}
                onClick={handlePrint}
              >
                Print
              </Button>
            </div>
          )}
          {exportAllLoading ? (
            <Spinner size={20} />
          ) : (
            <Button
              id="fs-export-properties"
              size="small"
              onClick={exportAll}
              disabled={properties.length === 0}
            >
              Export All
            </Button>
          )}
        </Toolbar>
        <div className={classes.tableWrapper}>
          {loading && (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          )}
          <Table<Property>
            hover
            className={classes.table}
            IDKey="property_id"
            selected={selected}
            data={properties}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            header={headRows}
            onSelect={handleSelect}
            onChangeOrder={handleChangeOrder}
            onRowClick={handlePropertySelect}
          />
        </div>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          rowsCount={properties.length}
          rowsTotal={propertiesTotal}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default React.memo(Properties);
