import axios from './axios';
import { Query, buildQuery } from '../utils/query';
import { BuilderFeedSettings } from '../redux/types/builders';

export const loadBuilders = <T>(queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get<T>(`/builders${query}`);
};

export const getBuilderDetail = (id: number) => axios.get(`/builders/${id}/detail`, {
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': '0',
  },
});

export const loadEnterpriseUserBuilders = (username: string) => axios.get(`/builders/enterprise/${username}`, {
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': '0',
  },
});

export const loadEnterpriseBuilders = (enterpriseId: number) => axios.get(`/builders/enterprise/id/${enterpriseId}`, {
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': '0',
  },
});

export const saveSettings = (settings: FormData) => axios.post('/builders/settings', settings);

export const saveDisclosures = (disclosures: any) => axios.post('/builders/settings/disclosures', disclosures);

export const saveMessaging = (messages: any) => axios.post('/builders/settings/messaging', messages);

export const loadSettings = (id: number) => axios.get(`/builders/settings/${id}`);

export const saveBilling = (billing: any) => axios.post('/builders/settings/billing', billing);

export const loadPMs = (id: number) => axios.get(`/builders/${id}`);

export const getBuilderIntegrations = () => axios.get('/builders/integrations');

export const getBuilderTimezone = () => axios.get('/builders/timezone');

export const updateBuilderTimezone = (timezone: number | null) => axios.post('/builders/timezone', { timezone });

export const getBuilderFeedSettings = (builderId: number) => axios.get(`/builders/feedSettings/${builderId}`);

export const updateBuilderFeedSettings = (feedSettings: BuilderFeedSettings) => axios.post('/builders/feedSettings', feedSettings);
