import { connect } from 'react-redux';
import { AppState } from '../../redux/store';
import PropertySearch from './PropertySearch';
import {selectCommunityProperties} from "../../redux/selectors/properties";
import {
  propertiesExportAndPrintRequest,
  propertiesExportRequest,
  propertiesRequest
} from "../../redux/actions/properties";
import {exportAllRequest} from "../../redux/actions/exportAll";
import {clearFilter} from "../../redux/actions/filter";

const mapStateToProps = (state: AppState) => ({
  properties: selectCommunityProperties(state),
});

const mapDispatchToProps = {
  loadProperties: propertiesRequest,
  exportProperties: propertiesExportRequest,
  printProperties: propertiesExportAndPrintRequest,
  exportAllProperties: exportAllRequest,
  clearFilterState: clearFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertySearch);
