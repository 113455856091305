import React from 'react';
import {
  Typography, FormControlLabel, Checkbox, Grid,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import useStyles from './styles';
import TextField from '@material-ui/core/TextField';

type Props = {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  roleValue: string;
  agentPayload: any;
  inputErrors: any;
};

const Notifications: React.FC<Props> = (props) => {
  const { handleChange, roleValue, agentPayload, inputErrors } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      sm={5}
      style={{ visibility: ['Sales Staff', 'Program Manager', 'Super Admin'].includes(roleValue) ? 'visible' : 'hidden' }}
    >
      <Typography variant="h6">
        Notifications
      </Typography>
      <Grid container direction="row">
        <FormControlLabel
          className={classes.customCheckbox}
          style={{ backgroundColor: agentPayload.send_sms ? '#3CB371' : 'rgba(0, 0, 0, 0.09)' }}
          control={(
            <Checkbox
              checkedIcon={<CheckIcon style={{ color: 'white' }} />}
              color="secondary"
              name="send_sms"
              checked={!!agentPayload.send_sms}
              onChange={handleChange}
            />
          )}
          label="Text"
          labelPlacement="end"
        />
        <Typography>Receive Leads by Text</Typography>
      </Grid>
      <Grid container direction="row">
        <FormControlLabel
          className={classes.customCheckbox}
          style={{ backgroundColor: agentPayload.send_email ? '#3CB371' : 'rgba(0, 0, 0, 0.09)' }}
          control={(
            <Checkbox
              checkedIcon={<CheckIcon style={{ color: 'white' }} />}

              color="secondary"
              name="send_email"
              checked={!!agentPayload.send_email}
              onChange={handleChange}
            />
          )}
          label="Email"
          labelPlacement="end"
        />
        <Typography>Receive Leads by Email</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {agentPayload.send_email === 1 && (
          <TextField
            margin="normal"
            fullWidth
            id="notification_email"
            label="Email for notifications"
            name="notification_email"
            onChange={handleChange}
            value={agentPayload.notification_email}
            error={Boolean(inputErrors.notification_email)}
            helperText={inputErrors.notification_email}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(Notifications);
