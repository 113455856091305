import axios from './axios';
import { Query, buildQuery } from '../utils/query';

export const loadAgents = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/agents${query}`, {
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  });
};

export const loadAvailableAgents = (builderId?: number) => axios.get(`/available_agents/${builderId}`);

export const createAgent = (agentPayload: Record<string, any>) => axios.post('/agents', agentPayload);

export const resendEmail = (data: any) => axios.post('/agents/resendEmail', data);

export const deleteAgentsByUsername = (payload: string[]) => axios.delete('/agents', { data: { usernames: payload } });

export const loadAgentById = (id: number | string) => axios.get(`/agents/${id}`);

export const updateAgentById = (
  agentPayload: Record<string, any>,
  id: string | number,
) => axios.patch(`/agents/${id}`, agentPayload);
