import React, { useEffect, useMemo, useState } from 'react';
import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import Spinner from '@material-ui/core/CircularProgress';
import { RouteComponentProps } from 'react-router-dom';
import useStyles from './styles';
import BillingRow from './components/BillingQuickbookRow';
import {
  BillingQuickbookCustomer,
  BillingQuickbookCustomerInvoice,
} from '../../redux/types/billingQuickbook';

type Props = {
  isLoading: boolean;
  customer: BillingQuickbookCustomer | null;
  customerInvoices: BillingQuickbookCustomerInvoice[];
  loadInvoice: (id: string) => void;
  loadCustomer: (id: string) => void;
  loadCustomerInvoices: (id: string) => void;
  error: string;
  quickbookId?: string;
} & RouteComponentProps;

// Filter options
type FilterOption = 'all' | 'unpaid' | 'paid';

const BillingQuickbook: React.FC<Props> = (props) => {
  const {
    isLoading,
    customer,
    customerInvoices,
    loadCustomer,
    loadCustomerInvoices,
    error,
    quickbookId,
  } = props;

  const [filterOption, setFilterOption] = useState<FilterOption>('all');

  const classes = useStyles();

  const mappedInvoices = useMemo(() => customerInvoices.map((item) => {
    let status = 'Pending';
    if (item.balance <= 0) {
      status = 'Paid';
    } else if (new Date(item.dueDate) < new Date()) {
      status = 'Overdue';
    }
    return { ...item, status };
  }), [customerInvoices]);

  useEffect(() => {
    if (!quickbookId) {
      return;
    }

    loadCustomer(quickbookId);
    loadCustomerInvoices(quickbookId);
  }, [loadCustomer, loadCustomerInvoices, quickbookId]);

  // Filter invoices when filterOption changes
  const filteredInvoices = useMemo(() => {
    switch (filterOption) {
      case 'unpaid':
        return mappedInvoices.filter((item) => item.status.toLowerCase() !== 'paid');
      case 'paid':
        return mappedInvoices.filter((item) => item.status.toLowerCase() === 'paid');
      default:
        return mappedInvoices;
    }
  }, [filterOption, mappedInvoices]);

  const handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilterOption(event.target.value as FilterOption);
  };

  const formatCurrency = (amount: number) => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);

  // Show loading spinner if loading from props or internal state
  if (isLoading) {
    return (
      <Grid container justify="center">
        <div className={classes.spinnerWrapper}>
          <Spinner />
        </div>
      </Grid>
    );
  }

  // Show error if any
  if (error) {
    return (
      <Grid container justify="center">
        <Typography color="error">{error}</Typography>
      </Grid>
    );
  }

  return (
    <>
      <Divider />
      <div className={classes.headerText}>
        <span>Billing Information</span>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.balancePaper} elevation={3}>
            <Typography variant="h6">Current Balance</Typography>
            <Typography variant="h3" className={classes.balanceAmount}>
              {formatCurrency(customer?.balance ?? 0)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h6" className={classes.invoicesHeader}>
                Invoice History
              </Typography>
            </Grid>
            <Grid item xs>
              <FormControl variant="outlined" size="small" style={{ minWidth: 150, marginLeft: 'auto' }}>
                <InputLabel id="invoice-filter-label">Filter</InputLabel>
                <Select
                  labelId="invoice-filter-label"
                  id="invoice-filter"
                  value={filterOption}
                  onChange={handleFilterChange}
                  label="Filter"
                >
                  <MenuItem value="all">All Invoices</MenuItem>
                  <MenuItem value="unpaid">Unpaid Invoices</MenuItem>
                  <MenuItem value="paid">Paid Invoices</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Paper className={classes.tablePaper}>
            <Table className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell />
                  <TableCell>Invoice #</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Due Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredInvoices.length > 0 ? (
                  filteredInvoices.map((item) => (
                    <BillingRow
                      key={item.id}
                      invoice={item}
                      formatCurrency={formatCurrency}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No invoices found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(BillingQuickbook);
