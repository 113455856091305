import { ErrorMessage, ValueOf } from '../../types/main';
import * as BillingQuickbookActions from '../actions/billingQuickbook';

export const BILLING_QUICKBOOK_INVOICE_REQUEST = 'BILLING_QUICKBOOK_INVOICE_REQUEST';
export const BILLING_QUICKBOOK_INVOICE_SUCCESS = 'BILLING_QUICKBOOK_INVOICE_SUCCESS';
export const BILLING_QUICKBOOK_INVOICE_FAILED = 'BILLING_QUICKBOOK_INVOICE_FAILED';

export const BILLING_QUICKBOOK_CUSTOMER_REQUEST = 'BILLING_QUICKBOOK_CUSTOMER_REQUEST';
export const BILLING_QUICKBOOK_CUSTOMER_SUCCESS = 'BILLING_QUICKBOOK_CUSTOMER_SUCCESS';
export const BILLING_QUICKBOOK_CUSTOMER_FAILED = 'BILLING_QUICKBOOK_CUSTOMER_FAILED';

export const BILLING_QUICKBOOK_CUSTOMER_INVOICES_REQUEST = 'BILLING_QUICKBOOK_CUSTOMER_INVOICES_REQUEST';
export const BILLING_QUICKBOOK_CUSTOMER_INVOICES_SUCCESS = 'BILLING_QUICKBOOK_CUSTOMER_INVOICES_SUCCESS';
export const BILLING_QUICKBOOK_CUSTOMER_INVOICES_FAILED = 'BILLING_QUICKBOOK_CUSTOMER_INVOICES_FAILED';

export type BillingQuickbookActionsTypes = ReturnType<ValueOf<typeof BillingQuickbookActions>>;

export interface Invoice extends BillingQuickbookCustomerInvoice {
  status: string;
}

export interface SalesLineItem {
  description: string;
  amount: number;
  itemName: string;
  itemId?: string;
  quantity: number;
  unitPrice: number;
}

export interface BillingQuickbookCustomer {
  id: string;
  name: string;
  balance: number;
  email: string;
  active: boolean;
  companyName: string;
  phone: string;
  billingAddress: {
    Id: string;
    Line1: string;
    Line2: string;
    City: string;
  }
}

export interface BillingQuickbookInvoice {
  invoiceId: string;
  invoiceLink: string;
  dueDate: string;
  salesLineItems: SalesLineItem[]
}

export interface BillingQuickbookCustomerInvoice {
  id: string;
  docNumber: string;
  balance: number;
  dueDate: string;
  totalAmount: number;
  salesLineItems: SalesLineItem[];
}

export interface BillingQuickbookState {
  error: ErrorMessage['message'];
  loading: boolean;
  customer: BillingQuickbookCustomer | null;
  invoice: BillingQuickbookInvoice | null;
  customerInvoices: BillingQuickbookCustomerInvoice[];
}

export type BillingQuickbookCustomerInvoicesResponse = BillingQuickbookCustomerInvoice[];
export type BillingQuickbookInvoiceDetailResponse = BillingQuickbookInvoice;
export type BillingQuickbookCustomerResponse = BillingQuickbookCustomer;
