import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  TextField,
  MenuItem,
  Grid,
  FormControlLabel,
  Checkbox,
  Tab,
  Tabs,
  Divider,
  Typography,
  Switch,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Spinner from '@material-ui/core/CircularProgress';
import { WithStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import PropertyImportImg from '../../assets/img/property_import.png';
import axios from '../../services/axios';
import appConfig from '../../config';
import styles from './styles';
import { BuilderFeedSettings } from '../../redux/types/builders';
import {
  createErrorSnackBar,
  createSuccessSnackBar,
} from '../../redux/actions/snackbars';

type Props = WithStyles<typeof styles> & {
  open: boolean;
  builder: string;
  builderFeedSettings: BuilderFeedSettings | null;
  saveFeedSettings: (feedSettings: BuilderFeedSettings) => void;
  changeOpen: () => void;
  integrationName: string;
};

type checkBoxValue = {
  label: string;
  value: string;
  type: string;
  required: boolean;
};

const feedSources = ['BDX', 'Zillow', 'ONeil Homefiniti', 'Custom Website'];
const checkBoxLabels: checkBoxValue[] = [
  {
    label: 'Subdivision',
    required: true,
    value: 'subdivision',
    type: 'string',
  },
  {
    label: 'Street Address',
    required: true,
    value: 'address_1',
    type: 'string',
  },
  {
    label: 'Street 2',
    required: false,
    value: 'address_2',
    type: 'string',
  },
  {
    label: 'City',
    required: true,
    value: 'city',
    type: 'string',
  },
  {
    label: 'State',
    required: true,
    value: 'state',
    type: 'string',
  },
  {
    label: 'Zip Code',
    required: true,
    value: 'zipcode',
    type: 'string',
  },
  {
    label: 'MLS Number',
    required: false,
    value: 'mls_number',
    type: 'string',
  },
  {
    label: 'Marketing Name',
    required: false,
    value: 'marketing_name',
    type: 'string',
  },
  {
    label: 'External Identifier',
    required: true,
    value: 'external_identifier',
    type: 'string',
  },
  {
    label: 'Price',
    required: false,
    value: 'price',
    type: 'number',
  },
  {
    label: 'Bedrooms',
    required: false,
    value: 'bedrooms',
    type: 'number',
  },
  {
    label: 'Bathrooms',
    required: false,
    value: 'bathrooms',
    type: 'number',
  },
];

const PropertyImportDialog: React.FC<Props> = (props) => {
  const {
    open,
    builder,
    changeOpen,
    builderFeedSettings,
    saveFeedSettings,
    integrationName,
    classes,
  } = props;

  const [fields, setFields] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [feedSettings, setFeedSettings] = useState<BuilderFeedSettings>({});
  const [index, setIndex] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (builderFeedSettings) {
      setFeedSettings(builderFeedSettings);
    }
  }, [builderFeedSettings]);

  useEffect(() => {
    if (builderFeedSettings?.feed_fields) {
      setFields(builderFeedSettings?.feed_fields);
      return;
    }

    const defaultFields = checkBoxLabels
      .map(({ value }) => value);

    setFields(defaultFields);
  }, [builderFeedSettings]);

  useEffect(() => {
    setFeedSettings((prevState) => ({ ...prevState, feed_fields: fields }));
  }, [fields]);

  const handleClose = () => {
    changeOpen();
  };

  const handleSyncProcess = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${appConfig.SCHEDULER_URL}scheduler/syncFeed`,
        {
          builder,
          feedSettings,
        },
      );

      dispatch(createSuccessSnackBar(data));
      setLoading(false);
    } catch (error) {
      dispatch(createErrorSnackBar(error.message));
      setLoading(false);
    }
  };

  const handleSave = async () => {
    saveFeedSettings(feedSettings);
  };

  const handleCheckBox = (checked: boolean, value: string) => {
    if (checked) {
      setFields((prevState) => [...prevState, value]);
      return;
    }

    const filteredFields = fields.filter((field) => field !== value);

    setFields(filteredFields);
  };

  const handleChangeFeedSettings = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value, checked } = e.target;

    if (name === 'add_communities_active') {
      setFeedSettings({
        ...feedSettings,
        add_communities_active: checked,
      });
      return;
    }

    if (feedSettings) {
      setFeedSettings({
        ...feedSettings,
        [name]: value,
      });
      return;
    }

    setFeedSettings({
      [name]: value,
    });
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
      >
        <DialogTitle>
          <img
            style={{ maxHeight: '60px' }}
            src={PropertyImportImg}
            alt=""
            className={classes.image}
          />
          <b>{integrationName}</b>
        </DialogTitle>
        <DialogContent style={{ minHeight: '60vh' }}>
          <Tabs
            value={index}
            onChange={(_, tabIndex) => {
              setIndex(tabIndex);
            }}
            variant="scrollable"
          >
            <Tab className={classes.tab} label="General" />
            <Tab className={classes.tab} label="Fields" />
          </Tabs>

          <Divider style={{ margin: '20px 0' }} />
          {index === 0 && (
            <Grid item container spacing={2} direction="column">
              <Grid item xs>
                <TextField
                  fullWidth
                  className={classes.select}
                  label="Feed Source"
                  name="feed_source"
                  select
                  value={feedSettings.feed_source}
                  onChange={handleChangeFeedSettings}
                >
                  <MenuItem value="" disabled>
                    Choose a Source
                  </MenuItem>
                  {feedSources.map((item: string) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {feedSettings?.feed_source && (
                <>
                  <Grid item xs>
                    <TextField
                      name="feed_url"
                      label="URL"
                      value={feedSettings.feed_url}
                      fullWidth
                      onChange={handleChangeFeedSettings}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      name="feed_username"
                      label="Username"
                      value={feedSettings.feed_username}
                      fullWidth
                      onChange={handleChangeFeedSettings}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      name="feed_password"
                      label="Password"
                      value={feedSettings.feed_password}
                      fullWidth
                      onChange={handleChangeFeedSettings}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      fullWidth
                      name="company_code"
                      label="Company Code"
                      value={feedSettings.company_code}
                      onChange={handleChangeFeedSettings}
                    />
                  </Grid>
                  <Grid item xs>
                    <FormControlLabel
                      control={(
                        <Switch
                          color="secondary"
                          name="add_communities_active"
                          onChange={handleChangeFeedSettings}
                          checked={Boolean(
                            feedSettings?.add_communities_active,
                          )}
                        />
                      )}
                      label={(
                        <Typography style={{ fontWeight: 700 }}>
                          Sync communities
                        </Typography>
                      )}
                      labelPlacement="end"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}
          {index === 1 && (
            <>
              <h3 style={{ marginTop: '20px' }}>
                Fields to Import: (*Required)
              </h3>
              {checkBoxLabels.map(
                ({ label, value, required }: checkBoxValue) => (
                  <Grid container direction="row" key={label}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          color="secondary"
                          name={value}
                          onChange={(e) => handleCheckBox(e.target.checked, value)}
                          checked={
                            required
                            || feedSettings.feed_fields?.includes(value)
                          }
                          disabled={required}
                        />
                      )}
                      label={`${required ? '*' : ''}${label}`}
                      labelPlacement="end"
                    />
                  </Grid>
                ),
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {loading && <Spinner size={20} />}
          {feedSettings?.feed_source && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSyncProcess}
              >
                Sync Now
              </Button>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </>
          )}

          <Button variant="contained" onClick={handleClose}>
            Exit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PropertyImportDialog;
