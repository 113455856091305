import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  headerText: {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '24px 0',
  },
  spinnerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  },
  balancePaper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    position: 'relative',
    borderRadius: '8px',
  },
  balanceAmount: {
    margin: theme.spacing(2, 0),
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  payButton: {
    margin: theme.spacing(2, 0, 1),
  },
  invoicesHeader: {
    margin: theme.spacing(3, 0, 2),
  },
  tablePaper: {
    borderRadius: '8px',
    overflow: 'hidden',
  },
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: theme.palette.grey[100],
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  statusBadge: {
    padding: '6px 12px',
    borderRadius: '16px',
    color: 'white',
    fontWeight: 'bold',
    display: 'inline-block',
    fontSize: '0.75rem',
    width: '80px',
    textAlign: 'center',
  },
  viewButton: {
    marginLeft: theme.spacing(1),
  },
  navButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(3, 0),
  },
  filterControl: {
    minWidth: 150,
    marginLeft: 'auto',
  },
}));

export default useStyles;
