import { createSelector } from 'reselect';
import { AppState } from '../store';
import { BillingQuickbookState } from '../types/billingQuickbook';

const selectBillingQuickBookState = (state: AppState) => state.billingQuickbook;
const selectInvoice = (state: BillingQuickbookState) => state.invoice;
const selectCustomer = (state: BillingQuickbookState) => state.customer;
const selectCustomerInvoices = (state: BillingQuickbookState) => state.customerInvoices;
const selectLoading = (state: BillingQuickbookState) => state.loading;
const selectError = (state: BillingQuickbookState) => state.error;

export const selectBillingQuickbookInvoiceSelected = createSelector(
  selectBillingQuickBookState,
  selectInvoice,
);

export const selectBillingQuickbookCustomerSelected = createSelector(
  selectBillingQuickBookState,
  selectCustomer,
);

export const selectBillingQuickbookCustomerInvoiceSelected = createSelector(
  selectBillingQuickBookState,
  selectCustomerInvoices,
);

export const selectBillingQuickbookLoadingSelected = createSelector(
  selectBillingQuickBookState,
  selectLoading,
);

export const selectBillingQuickbookErrorSelected = createSelector(
  selectBillingQuickBookState,
  selectError,
);
