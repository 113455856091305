import {
  Card, CardContent, Grid, Typography,
} from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

const Training = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.headerText}>
        <span>Training</span>
      </div>
      <Grid container spacing={4} className={classes.container}>
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardContent>
              <div
                style={{
                  position: 'relative',
                  paddingBottom: '53.65126676602087%',
                  height: 0,
                }}
              >
                <iframe
                  title="Creating Staff in your NterNow dashboard"
                  src="https://www.loom.com/embed/1b6dbe79df234172b52286de7c3aebab?sid=241569e6-0060-4f61-8022-058907329ea8&hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                  frameBorder="0"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
              <Typography className={classes.videoTitle}>
                Creating Staff in your NterNow dashboard
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardContent>
              <div
                style={{
                  position: 'relative',
                  paddingBottom: '53.65126676602087%',
                  height: 0,
                }}
              >
                <iframe
                  title="Creating a Team of staff in your NterNow dashboard"
                  src="https://www.loom.com/embed/ddf78600bf8245fcb66d720515a8e292?sid=c754cc00-dd55-4382-84f3-7177a17e3e63&hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                  frameBorder="0"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
              <Typography className={classes.videoTitle}>
                Creating a Team of staff in your NterNow dashboard
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardContent>
              <div
                style={{
                  position: 'relative',
                  paddingBottom: '53.65126676602087%',
                  height: 0,
                }}
              >
                <iframe
                  title="How to create communities in your NterNow Dashboard"
                  src="https://www.loom.com/embed/15b56b9f07864484b40f59ed2b94f7a1?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                  frameBorder="0"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
              <Typography className={classes.videoTitle}>
                How to create communities in your NterNow Dashboard
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardContent>
              <div
                style={{
                  position: 'relative',
                  paddingBottom: '53.65126676602087%',
                  height: 0,
                }}
              >
                <iframe
                  title="How to add Master Codes to a Community"
                  src="https://www.loom.com/embed/87ec6b8101024a9993759f9f9590159b?sid=d10d8f41-88e2-4734-8c1b-c0e1d6026ce8&hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                  frameBorder="0"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
              <Typography className={classes.videoTitle}>
                How to add Master Codes to a Community
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardContent>
              <div
                style={{
                  position: 'relative',
                  paddingBottom: '53.65126676602087%',
                  height: 0,
                }}
              >
                <iframe
                  title="How to create properties in your dashboard"
                  src="https://www.loom.com/embed/6851c5afbee94594949999cd25129c8f?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                  frameBorder="0"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
              <Typography className={classes.videoTitle}>
                How to create properties in your dashboard
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardContent>
              <div
                style={{
                  position: 'relative',
                  paddingBottom: '53.65126676602087%',
                  height: 0,
                }}
              >
                <iframe
                  title="How to edit your survey questions"
                  src="https://www.loom.com/embed/a5ffa0fc13214224b38b87ddf727a156?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                  frameBorder="0"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
              <Typography className={classes.videoTitle}>
                How to edit your survey questions
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardContent>
              <div
                style={{
                  position: 'relative',
                  paddingBottom: '53.65126676602087%',
                  height: 0,
                }}
              >
                <iframe
                  title="How to pre-register visitors with NterNow"
                  src="https://www.loom.com/embed/4ef27d2f74c742f3ba7e0cf9e631b2ee?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                  frameBorder="0"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
              <Typography className={classes.videoTitle}>
                How to pre-register visitors with NterNow
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardContent>
              <div
                style={{
                  position: 'relative',
                  paddingBottom: '53.65126676602087%',
                  height: 0,
                }}
              >
                <iframe
                  title="Where to find your Self-Tour landing page"
                  src="https://www.loom.com/embed/15a76ed186ed496195c30da98c214eb3?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                  frameBorder="0"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
              <Typography className={classes.videoTitle}>
                Where to find your Self-Tour landing page
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardContent>
              <div
                style={{
                  position: 'relative',
                  paddingBottom: '53.65126676602087%',
                  height: 0,
                }}
              >
                <iframe
                  title="New Tour Hours option"
                  src="https://www.loom.com/embed/82c8ff63ad22496299f675899ead5c17?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                  frameBorder="0"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
              <Typography className={classes.videoTitle}>
                New Tour Hours option
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardContent>
              <div
                style={{
                  position: 'relative',
                  paddingBottom: '53.65126676602087%',
                  height: 0,
                }}
              >
                <iframe
                  title="How to find your Global URL"
                  src="https://www.loom.com/embed/934c529f08e4441e88b695626da9e05c?sid=c217de22-5a3a-4a70-8cd2-e4679ecdc391?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                  frameBorder="0"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
              <Typography className={classes.videoTitle}>
                How to find your Global URL
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardContent>
              <div
                style={{
                  position: 'relative',
                  paddingBottom: '53.65126676602087%',
                  height: 0,
                }}
              >
                <iframe
                  title="How to manually sync your NterNow properties"
                  src="https://www.loom.com/embed/166e7c219d344d4b8fb2229677ffdbf8?sid=c75f2a94-3311-4d31-a56f-968f59f50135?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                  frameBorder="0"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
              <Typography className={classes.videoTitle}>
                How to manually sync your NterNow properties
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardContent>
              <div
                style={{
                  position: 'relative',
                  paddingBottom: '53.65126676602087%',
                  height: 0,
                }}
              >
                <iframe
                  title="Activating your NterNow communties"
                  src="https://www.loom.com/embed/e62a2d945d6d4ad09deaa71b0cb92bbe?sid=04de2aae-2a00-4652-9684-6aaa23188675?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                  frameBorder="0"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
              <Typography className={classes.videoTitle}>
                Activating your NterNow communties
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Training;
