import {
  PROPERTIES_REQUEST,
  PROPERTIES_REQUEST_FAILED,
  PROPERTIES_REQUEST_SUCCESS,
  PropertiesActionsTypes,
  PropertiesState,
  PROPERTY_ADD_AGENT_REQUEST,
  PROPERTY_ADD_AGENT_REQUEST_FAILED,
  PROPERTY_ADD_AGENT_REQUEST_SUCCESS,
  PROPERTY_DETAILS_REQUEST,
  PROPERTY_DETAILS_REQUEST_FAILED,
  PROPERTY_DETAILS_REQUEST_SUCCESS,
  PROPERTY_REMOVE_AGENT_REQUEST,
  PROPERTY_REMOVE_AGENT_REQUEST_FAILED,
  PROPERTY_REMOVE_AGENT_REQUEST_SUCCESS,
  PROPERTY_TOURS_REPORT_REQUEST,
  PROPERTY_TOURS_REPORT_REQUEST_SUCCESS,
  PROPERTY_TOURS_REPORT_REQUEST_FAILED,
  PROPERTIES_BY_COMMUNITY_REQUEST,
  PROPERTIES_BY_COMMUNITY_REQUEST_FAILED,
  PROPERTIES_BY_COMMUNITY_REQUEST_SUCCESS
} from '../types/properties';

const initialState: PropertiesState = {
  items: [],
  communityProperties: [],
  total: 0,
  error: '',
  loading: false,
  updating: false,
  selected: null,
  propertyTours: null
};

export default function PropertiesReducer(
  state = initialState,
  action: PropertiesActionsTypes,
): PropertiesState {
  switch (action.type) {
    case PROPERTIES_BY_COMMUNITY_REQUEST:
    case PROPERTY_DETAILS_REQUEST:
    case PROPERTIES_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case PROPERTIES_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total,
        error: '',
        loading: false,
      };
    }

    case PROPERTY_DETAILS_REQUEST_SUCCESS: {
      return {
        ...state,
        error: '',
        selected: action.payload,
        loading: false,
      };
    }

    case PROPERTIES_BY_COMMUNITY_REQUEST_SUCCESS: {
      return {
        ...state,
        communityProperties: action.payload,
        loading: false,
      };
    }

    case PROPERTIES_BY_COMMUNITY_REQUEST_FAILED:
    case PROPERTY_DETAILS_REQUEST_FAILED:
    case PROPERTIES_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case PROPERTY_ADD_AGENT_REQUEST:
    case PROPERTY_REMOVE_AGENT_REQUEST: {
      return {
        ...state,
        updating: true,
      };
    }

    case PROPERTY_ADD_AGENT_REQUEST_FAILED:
    case PROPERTY_REMOVE_AGENT_REQUEST_FAILED: {
      return {
        ...state,
        updating: false,
      };
    }

    case PROPERTY_ADD_AGENT_REQUEST_SUCCESS: {
      const { selected } = state;

      if (selected) {
        const { agents } = selected;

        return {
          ...state,
          selected: {
            ...selected,
            agents: [...agents, action.payload],
          },
          updating: false,
        };
      }

      return {
        ...state,
        updating: false,
      };
    }

    case PROPERTY_REMOVE_AGENT_REQUEST_SUCCESS: {
      const { selected } = state;

      if (selected) {
        const { agents } = selected;

        return {
          ...state,
          selected: {
            ...selected,
            agents: agents.filter((agent) => agent.agent_username !== action.payload),
          },
          updating: false,
        };
      }

      return {
        ...state,
        updating: false,
      };
    }

    case PROPERTY_TOURS_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROPERTY_TOURS_REPORT_REQUEST_SUCCESS: {
      return {
        ...state,
        propertyTours: action.payload.data,
        loading: false,
        total: action.payload.totalCount
      };
    }

    case PROPERTY_TOURS_REPORT_REQUEST_FAILED:
    default: {
      return {...state, loading: false,};
    }
  }
}
