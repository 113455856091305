import axios from './axios';
import {
  GetHubDevicesResponse,
  UpdateHubLightInput,
  HubLockAutoLockInput,
  GetHubInfoResponse,
  HubLockCodeInput,
  HubLockStatusInput,
  HubThermostatInput,
  DeleteHubLockCodeInput,
  UpdateOrCreateHubLockCodeInput,
  Hub,
  HubLockLogType,
  HubActivityLogsResponse,
} from '../redux/types/hubs';
import { Query, buildQuery } from '../utils/query';
import { Order } from '../utils/table';

export const getHubDevices = (id: string): Promise<GetHubDevicesResponse> => axios.get(`/hubs/${id}/devices`);

export const getHubReboot = (id: string) => axios.get(`/hubs/${id}/reboot`);

export const getHubsByBuilderId = (queryObject?: Query): Promise<Hub[]> => {
  const query = buildQuery(queryObject);

  return axios.get(`/hubs${query}`, {
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  });
};

export const getHubInfo = (id: string): Promise<GetHubInfoResponse> => axios.get(`/hubs/${id}/info`);

// TODO: remove lock id
export const updateHubLockStatus = ({ hubId, ...payload }: HubLockStatusInput) => axios
  .patch(`/hubs/${hubId}/lock/status`, payload);

// TODO: remove lock id
export const updateHubLockAutoLock = ({ hubId, ...payload }: HubLockAutoLockInput) => axios
  .patch(`/hubs/${hubId}/lock/auto-lock`, payload);

export const createHubLockCode = ({ hubId, ...payload }: HubLockCodeInput) => axios
  .post(`/hubs/${hubId}/lock/code`, payload);

export const updateHubLockCode = ({ hubId, ...payload }: HubLockCodeInput) => axios
  .patch(`/hubs/${hubId}/lock/code`, payload);

export const updateHubLockCodes = ({ hubId, ...payload }: UpdateOrCreateHubLockCodeInput) => axios
  .patch(`/hubs/${hubId}/lock/codes`, payload);

export const getLockLogs = (hubId: string, queryObject?: Query) => {
  const query = buildQuery(queryObject);
  return axios.get<HubLockLogType[]>(`/hubs/${hubId}/lock-logs${query}`);
};

export const deleteHubLockCode = ({ hubId, ...payload }: DeleteHubLockCodeInput) => axios
  .delete(`/hubs/${hubId}/lock/code`, {
    data: payload,
  });

export const updateHubLight = (payload: UpdateHubLightInput) => axios.patch(`/hubs/${payload.hubId}/lights/${payload.id}`, payload);

export const updateHubThermostat = (payload: HubThermostatInput) => axios.patch(`/hubs/${payload.id}/thermostat`, payload);

export const getHubActivityLogs = (params: {
  search: string, page: number, limit: number,
  order: Order, orderBy: string,
}) => axios.get<HubActivityLogsResponse>('/hubs/activity-logs', {
  params,
}).then((res) => res.data);

export const exportAllActivityHubLogs = (search: string) => axios.post('/hubs/activity-logs/exportAll', {
  search,
}, {
  responseType: 'blob',
});
