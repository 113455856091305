import { createStyles, makeStyles } from '@material-ui/core';

const styles = () => createStyles({
  propertySelect: {
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  root: {
    paddingLeft: 12,
    marginBottom: 8,
    height: 50,
    '& label': {
      marginBottom: '-5px'
    },
    '& .css-1pcexqc-container > div > div > div': {
      fontFamily: 'Source Sans Pro',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 16,
      display: 'flex',
      alignItems: 'flex-end',
      color: 'rgba(0, 0, 0, 0.87)',
      marginLeft: 0,
    }
  }
});

const useStyles = makeStyles(styles);

export default useStyles;
