import { all, fork } from 'redux-saga/effects';
import authSaga from './auth';
import properties from './properties';
import customers from './customers';
import visits from './visits';
import offers from './offers';
import locks from './locks';
import smartLocks from './smartLocks';
import stats from './stats';
import catalog from './catalog';
import states from './states';
import agents from './agents';
import appInit from './appInit';
import split from './split';
import feedback from './feedback';
import billing from './billing';
import communities from './communities';
import exportAll from './exportAll';
import integrations from './integrations';
import scheduledVisits from './scheduledVisits';
import builders from './builders';
import hubs from './hubs';
import teams from './teams';
import survey from './survey';
import afterTourHours from './afterTourHours';
import webhooks from './webhooks';
import reports from './reports';
import billingQuickbook from './billingQuickbook';


export default function* rootSaga() {
  yield fork(split);
  yield all([
    authSaga,
    properties,
    customers,
    visits,
    locks,
    smartLocks,
    stats,
    catalog,
    states,
    agents,
    appInit,
    feedback,
    offers,
    billing,
    communities,
    exportAll,
    integrations,
    scheduledVisits,
    builders,
    hubs,
    teams,
    survey,
    afterTourHours,
    webhooks,
    reports,
    billingQuickbook,
  ]);
}
