import axios from './axios';
import { TeamFormType } from '../redux/types/teams';
import { buildQuery, Query } from '../utils/query';
import { Agent } from '../redux/types/agents';

export const loadTeams = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/teams${query}`, {
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  });
};

export const loadTeamsByBuilder = (builderId: number) => axios.get(`/teams/builder/${builderId}`);

export const loadTeamById = (id: number | string) => axios.get(`/teams/${id}`);

export const createTeamData = (team: TeamFormType) => axios.post('/teams/add', team);

export const updateTeamData = ({
  teamId,
  team,
}: {
  teamId: number | string;
  team: TeamFormType;
}) => axios.post(`/teams/${teamId}`, team);

export const deleteTeams = (teamIds: any) => axios.delete('/teams', { data: teamIds });

export const addTeamAgent = ({
  teamId,
  agent,
}: {
  teamId: number | string;
  agent: Agent;
}) => axios.post(`/teams/${teamId}/agent`, { agent });

export const deleteTeamAgent = ({
  teamId,
  agent,
}: {
  teamId: number | string;
  agent: Agent;
}) => axios.delete(`/teams/${teamId}/agent`, { data: { agent } });
