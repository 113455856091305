import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import {
  billingQuickbookCustomerFailed,
  billingQuickbookCustomerInvoicesFailed,
  billingQuickbookCustomerInvoicesRequest,
  billingQuickbookCustomerInvoicesSuccess,
  billingQuickbookCustomerRequest,
  billingQuickbookCustomerSuccess,
  billingQuickbookInvoiceFailed,
  billingQuickbookInvoiceRequest,
  billingQuickbookInvoiceSuccess,
} from '../actions/billingQuickbook';
import { loadCustomer, loadCustomerInvoices, loadInvoice } from '../../services/billingQuickbook';
import {
  BILLING_QUICKBOOK_CUSTOMER_INVOICES_REQUEST,
  BILLING_QUICKBOOK_CUSTOMER_REQUEST,
  BILLING_QUICKBOOK_INVOICE_REQUEST
} from '../types/billingQuickbook';

function* loadBillingQuickbookInvoiceSaga(
  action: ReturnType<typeof billingQuickbookInvoiceRequest>,
) {
  try {
    const { data } = yield call(loadInvoice, action.payload);
    yield put(billingQuickbookInvoiceSuccess(data));
  } catch (err) {
    yield put(billingQuickbookInvoiceFailed(err));
  }
}

function* loadBillingQuickbookCustomerSaga(
  action: ReturnType<typeof billingQuickbookCustomerRequest>,
) {
  try {
    const { data } = yield call(loadCustomer, action.payload);
    yield put(billingQuickbookCustomerSuccess(data));
  } catch (err) {
    yield put(billingQuickbookCustomerFailed(err));
  }
}

function* loadBillingQuickbookCustomerInvoicesSaga(
  action: ReturnType<typeof billingQuickbookCustomerInvoicesRequest>,
) {
  try {
    const { data } = yield call(loadCustomerInvoices, action.payload);
    yield put(billingQuickbookCustomerInvoicesSuccess(data));
  } catch (err) {
    yield put(billingQuickbookCustomerInvoicesFailed(err));
  }
}

export default all([
  takeLatest<any>(BILLING_QUICKBOOK_INVOICE_REQUEST, loadBillingQuickbookInvoiceSaga),
  takeLatest<any>(BILLING_QUICKBOOK_CUSTOMER_REQUEST, loadBillingQuickbookCustomerSaga),
  takeLatest<any>(
    BILLING_QUICKBOOK_CUSTOMER_INVOICES_REQUEST,
    loadBillingQuickbookCustomerInvoicesSaga,
  ),
]);
