import { connect } from 'react-redux';
import { AppState } from '../../redux/store';
import BillingQuickbook from './BillingQuickbook';
import {
  billingQuickbookCustomerInvoicesRequest,
  billingQuickbookCustomerRequest,
} from '../../redux/actions/billingQuickbook';
import {
  selectBillingQuickbookCustomerInvoiceSelected,
  selectBillingQuickbookCustomerSelected,
  selectBillingQuickbookErrorSelected,
  selectBillingQuickbookLoadingSelected,
} from '../../redux/selectors/billingQuickbook';
import { getBuilderQuickbookId } from '../../redux/selectors/auth';

const mapStateToProps = (state: AppState) => ({
  isLoading: selectBillingQuickbookLoadingSelected(state),
  customer: selectBillingQuickbookCustomerSelected(state),
  customerInvoices: selectBillingQuickbookCustomerInvoiceSelected(state),
  error: selectBillingQuickbookErrorSelected(state),
  quickbookId: getBuilderQuickbookId(state),
});

const mapDispatchToProps = {
  loadCustomer: billingQuickbookCustomerRequest,
  loadCustomerInvoices: billingQuickbookCustomerInvoicesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingQuickbook);
