import { Theme, createStyles, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  headerText: {
    textAlign: 'left',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '20px',
  },

  subtitle: {
    fontSize: '16px',
    fontWeight: 400,
    padding: '10px 0',
  },

  disabled: {
    borderColor: '#E0E0E0',
    filter: 'grayscale(1)',
    opacity: '0.5',
    pointerEvents: 'none',
  },

  navButtons: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
    marginTop: '50px',
    marginRight: '100px',
  },
  submitButtons: {
    height: '40px',
    width: '130px',
    fontSize: '14px',
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  textArea: {
    marginLeft: '45px',
    width: '40vw',
    resize: 'none',
    fontSize: '13px',
    letterSpacing: '1.2px',
  },
  table: {
    // marginLeft: '45px',
    // width: '500px',
    borderCollapse: 'separate',
    borderSpacing: 0,
    // minHeight: '270px',
    height: '100%',
  },
  dropdownTable: {
    // marginLeft: '45px',
    // width: '500px',
    borderCollapse: 'separate',
    borderSpacing: 0,
    // minHeight: '270px',
    height: '250px',
    marginBottom: '20px',
  },
  tableHead: {
    backgroundColor: theme.appPalette.orange,
    border: `1px solid ${theme.appPalette.orange}`,

    '& th': {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: 0.2,
      color: '#FFF',
    },
  },
  tableRow: {
    '& td:first-child': {
      borderLeftStyle: 'solid',
      borderTopStyle: 'solid',
    },
    '&:last-child td:first-child': {
      borderBottomLeftRadius: '10px',
      borderTopLeftRadius: '10px',
    },
    '&:last-child td:last-child': {
      borderBottomRightRadius: '10px',
      borderTopRightRadius: '10px',
    },
  },
  tableCell: {
    fontSize: '16px',
    padding: '0 10px',
    border: '1px solid #8E8B9933',
    borderStyle: 'none solid solid none',
  },
  messageBox: {
    border: '2px solid #8E8B9933',
    borderRadius: '10px',
    margin: '0 20px 30px 0',
    padding: '20px',
  },
  previewBox: {
    backgroundColor: '#EBF8FF',
    border: '2px solid #8E8B9933',
    padding: '20px',
    marginTop: '20px',
  },
  previewTitle: {
    fontWeight: 700,
    color: '#2B6CB0',
  },
  previewWithIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    marginBottom: '0.5rem',
  },
  previewIcon: {
    width: '1.25rem',
    height: '1.25rem',
    color: '#4299E1',
  },
  textLimit: {
    textAlign: 'right',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    color: '#6B7280',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
