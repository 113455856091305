import axios from './axios';
import { Query, buildQuery } from '../utils/query';
import { Community } from '../redux/types/communities';

const requestConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// eslint-disable-next-line import/prefer-default-export
export const getCommunitiesList = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/communities${query}`, {
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  });
};

export const fetchCommunityDetails = (communityId: number) => axios.get(`/communities/${communityId}`);

export const getQRCodesData = (communityId: number) => axios.get(`/communities/${communityId}/QRCode`);

export const saveQRCode = (payload: any) => axios.post(`/communities/${payload.communityId}/saveQRCode`, {
  QRCodeText: payload.QRCodeText,
  QRCodeName: payload.QRCodeName,
});

export const fetchCommunityExternalIds = () => axios.get('/communities/externalIds', requestConfig);

export const saveCommunityDetails = (community: Community) => axios.post('/communities/saveCommunity', { community });

export const fetchBuilderCommunities = (builderId: number) => axios.get(`/communities/builder/${builderId}`);

export const deleteCommunitiesRequest = (selected: Array<number | string>) => axios.delete('/communities', { data: selected });

export const deleteQRCodeRequest = (id: number) => axios.post('/communities/QRCode_delete', { id });

export const fetchCommunitiesStatuses = () => axios.get(`/communities/statuses`);

export const exportCommunitiesRequest = (
  selection: Array<number | string>,
  mimeType: MimeType,
) => {
  const headers = {
    Accept: mimeType,
  };

  return axios.post(
    '/communities/export',
    {
      selection,
    },
    { headers, responseType: 'blob' },
  );
};
