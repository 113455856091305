import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';

import { AUTH_ADMIN_SUCCESS } from '../types/auth';
import {
  loadBuilders,
  loadPMs,
  loadEnterpriseBuilders,
  saveSettings,
  loadSettings,
  getBuilderIntegrations,
  getBuilderTimezone,
  updateBuilderTimezone,
  getBuilderFeedSettings,
  updateBuilderFeedSettings,
  loadEnterpriseUserBuilders,
  saveDisclosures,
  saveMessaging,
} from '../../services/builders';

import {
  buildersRequestSuccess,
  buildersRequestFailed,
  enterpriseBuildersRequestSuccess,
  enterpriseBuildersRequestFailed,
  buildersRequest,
  enterpriseBuildersRequest,
  builderSettingsRequest,
  builderSettingsRequestFailed,
  builderSettingsRequestSuccess,
  saveBuilderSettings,
  saveBuilderSettingsFailed,
  saveBuilderSettingsSuccess,
  builderIntegrationsSuccess,
  builderTimezoneSuccess,
  builderTimezoneUpdateRequest,
  builderTimezoneUpdateSuccess,
  builderTimezoneUpdateFailed,
  builderFeedSettingsRequestSuccess,
  updateBuilderFeedSettingsRequest,
  updateBuilderFeedSettingsSuccess,
  updateBuilderFeedSettingsFailed,
  builderFeedSettingsRequest,
  enterpriseUserBuildersRequest,
  enterpriseUserBuildersRequestFailed,
  enterpriseUserBuildersRequestSuccess,
  saveBuilderDisclosures,
  saveBuilderDisclosuresSuccess,
  saveBuilderDisclosuresFailed,
  saveBuilderMessaging,
  saveBuilderMessagingSuccess,
  saveBuilderMessagingFailed,
} from '../actions/builders';
import {
  buildersPMsRequestSuccess,
  buildersPMsRequestFailed,
  buildersPMsRequest,
} from '../actions/builderPMs';
import {
  BUILDERS_REQUEST,
  BUILDERS_PMS_REQUEST,
  ENTERPRISE_BUILDERS_REQUEST,
  SAVE_BUILDER_SETTINGS,
  BUILDERS_SETTINGS_REQUEST,
  BUILDER_INTEGRATIONS_REQUEST,
  BUILDER_TIMEZONE_REQUEST,
  BUILDER_TIMEZONE_UPDATE_REQUEST,
  BUILDER_FEED_SETTINGS_REQUEST,
  BUILDER_FEED_SETTINGS_UPDATE,
  ENTERPRISE_USER_BUILDERS_REQUEST,
  SAVE_BUILDER_DISCLOSURES,
  SAVE_BUILDER_MESSAGING,
} from '../types/builders';
import {
  createErrorSnackBar,
  createSuccessSnackBar,
} from '../actions/snackbars';

function* loadBuilderSaga(action: ReturnType<typeof buildersRequest>) {
  try {
    const { data } = yield call(loadBuilders, action.payload);
    yield put(buildersRequestSuccess(data));
  } catch (err) {
    yield put(buildersRequestFailed(err));
  }
}

function* loadBuilderSettingsSaga(
  action: ReturnType<typeof builderSettingsRequest>,
) {
  try {
    const { data } = yield call(loadSettings, action.payload);
    yield put(builderSettingsRequestSuccess(data));
  } catch (err) {
    yield put(builderSettingsRequestFailed(err));
  }
}

function* getBuilderIntegrationsSaga() {
  try {
    const { data } = yield call(getBuilderIntegrations);

    yield put(builderIntegrationsSuccess(data));
  } catch (error) {
    yield put(
      createErrorSnackBar(
        error.response ? error.response.data.message : error.message,
      ),
    );
  }
}

function* saveBuilderSettingsSaga(
  action: ReturnType<typeof saveBuilderSettings>,
) {
  try {
    const { data } = yield call(saveSettings, action.payload);
    yield put(saveBuilderSettingsSuccess(data));
    yield put(createSuccessSnackBar('Success!'));
  } catch (err) {
    yield put(saveBuilderSettingsFailed(err));
  }
}

function* saveBuilderDisclosuresSaga(
  action: ReturnType<typeof saveBuilderDisclosures>,
) {
  try {
    const { data } = yield call(saveDisclosures, action.payload);
    yield put(saveBuilderDisclosuresSuccess(data));
    yield put(createSuccessSnackBar('Success!'));
  } catch (err) {
    yield put(saveBuilderDisclosuresFailed(err));
    yield put(
      createErrorSnackBar(
        err.response ? err.response.data.message : err.message,
      ),
    );
  }
}

function* saveBuilderMessagingSaga(
  action: ReturnType<typeof saveBuilderMessaging>,
) {
  try {
    const { data } = yield call(saveMessaging, action.payload);
    yield put(saveBuilderMessagingSuccess(data));
    yield put(createSuccessSnackBar('Success!'));
  } catch (err) {
    yield put(saveBuilderMessagingFailed(err));
    yield put(
      createErrorSnackBar(
        err.response ? err.response.data.message : err.message,
      ),
    );
  }
}

function* loadEnterpriseBuildersSaga(
  action: ReturnType<typeof enterpriseBuildersRequest>,
) {
  try {
    const { data } = yield call(loadEnterpriseBuilders, action.payload);
    yield put(enterpriseBuildersRequestSuccess(data));
  } catch (err) {
    yield put(enterpriseBuildersRequestFailed(err));
  }
}

function* loadEnterpriseUserBuildersSaga(
  action: ReturnType<typeof enterpriseUserBuildersRequest>,
) {
  try {
    const { data } = yield call(loadEnterpriseUserBuilders, action.payload);
    yield put(enterpriseUserBuildersRequestSuccess(data));
  } catch (err) {
    yield put(enterpriseUserBuildersRequestFailed(err));
  }
}

function* loadBuilderPMsSaga(action: ReturnType<typeof buildersPMsRequest>) {
  try {
    const { data } = yield call(loadPMs, action.payload);
    yield put(buildersPMsRequestSuccess(data));
  } catch (err) {
    yield put(buildersPMsRequestFailed(err));
  }
}

function* getBuilderTimezoneSaga() {
  try {
    const { data } = yield call(getBuilderTimezone);

    yield put(builderTimezoneSuccess(data));
  } catch (error) {
    yield put(
      createErrorSnackBar(
        error.response ? error.response.data.message : error.message,
      ),
    );
  }
}

function* updateBuilderTimezoneSaga(
  action: ReturnType<typeof builderTimezoneUpdateRequest>,
) {
  try {
    const { data } = yield call(updateBuilderTimezone, action.payload);
    yield put(builderTimezoneUpdateSuccess(data));
    yield put(createSuccessSnackBar('Timezone Saved!'));
  } catch (err) {
    yield put(builderTimezoneUpdateFailed(err));
    yield put(
      createErrorSnackBar(
        err.response ? err.response.data.message : err.message,
      ),
    );
  }
}

function* getBuilderFeedSettingsSaga(
  action: ReturnType<typeof builderFeedSettingsRequest>,
) {
  try {
    const { data } = yield call(getBuilderFeedSettings, action.payload);

    yield put(builderFeedSettingsRequestSuccess(data));
  } catch (error) {
    yield put(
      createErrorSnackBar(
        error.response ? error.response.data.message : error.message,
      ),
    );
  }
}

function* updateBuilderFeedSettingsSaga(
  action: ReturnType<typeof updateBuilderFeedSettingsRequest>,
) {
  try {
    const { data } = yield call(updateBuilderFeedSettings, action.payload);
    yield put(updateBuilderFeedSettingsSuccess(data));
    yield put(createSuccessSnackBar('Saved!'));
  } catch (err) {
    yield put(updateBuilderFeedSettingsFailed(err));
    yield put(
      createErrorSnackBar(
        err.response ? err.response.data.message : err.message,
      ),
    );
  }
}

export default all([
  takeLatest<any>(AUTH_ADMIN_SUCCESS, loadBuilderSaga),
  takeLatest<any>(BUILDERS_REQUEST, loadBuilderSaga),
  takeLatest<any>(SAVE_BUILDER_DISCLOSURES, saveBuilderDisclosuresSaga),
  takeLatest<any>(SAVE_BUILDER_MESSAGING, saveBuilderMessagingSaga),
  takeLatest<any>(SAVE_BUILDER_SETTINGS, saveBuilderSettingsSaga),
  takeLatest<any>(BUILDERS_SETTINGS_REQUEST, loadBuilderSettingsSaga),
  takeLatest<any>(
    ENTERPRISE_USER_BUILDERS_REQUEST,
    loadEnterpriseUserBuildersSaga,
  ),
  takeLatest<any>(ENTERPRISE_BUILDERS_REQUEST, loadEnterpriseBuildersSaga),
  takeLatest<any>(BUILDERS_PMS_REQUEST, loadBuilderPMsSaga),
  takeLatest<any>(BUILDER_INTEGRATIONS_REQUEST, getBuilderIntegrationsSaga),
  takeLatest<any>(BUILDER_TIMEZONE_REQUEST, getBuilderTimezoneSaga),
  takeLatest<any>(BUILDER_TIMEZONE_UPDATE_REQUEST, updateBuilderTimezoneSaga),
  takeLatest<any>(BUILDER_FEED_SETTINGS_REQUEST, getBuilderFeedSettingsSaga),
  takeLatest<any>(BUILDER_FEED_SETTINGS_UPDATE, updateBuilderFeedSettingsSaga),
]);
