/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Spinner from '@material-ui/core/CircularProgress';
import TableCell from '@material-ui/core/TableCell';
import { RouteComponentProps } from 'react-router-dom';
import SearchBar from '../SearchBar';
import TablePagination from '../../components/CustomTablePagination';
import Table from '../../components/CustomTable';
import { ROWS_PER_PAGE_OPTIONS } from '../../constants/table';
import { formatToESTDate } from '../../utils/date';
import useVisits from './hooks/useVisits.hook';
import { selectVisitsFilters } from '../../redux/selectors/filter';
import { useSelector } from 'react-redux';

import styles from './styles';
import { HeaderRowConfig } from '../../components/CustomTable/CustomTable';
import { Order } from '../../utils/table';
import { Visit } from '../../redux/types/visits';
import { Query } from '../../utils/query';
import { ExportAllParams } from '../../redux/types/exportAll';
import { MimeType } from '../../redux/types/mime';

type Props = {
  loading: boolean;
  exportAllLoading: boolean;
  visits: Visit[];
  visitsTotal: number;
  loadVisits: (query?: Query) => void;
  exportVisits: (selection: Array<number | string>) => void;
  exportAllVisits: (path: string, mimeType: MimeType, params: ExportAllParams, query?: Query) => void;
  printVisits: (selection: Array<number | string>) => void;
  clearFilterState: () => void;
} & WithStyles<typeof styles> & RouteComponentProps;

const searchBarPlaceholder: string = 'Search all visits';

const headRows: HeaderRowConfig<Visit>[] = [
  {
    key: 'firstname',
    align: 'left',
    disablePadding: false,
    label: 'First Name',
  },
  {
    key: 'lastname',
    align: 'left',
    disablePadding: false,
    label: 'Last Name',
  },
  {
    key: 'cell_phone_1',
    align: 'right',
    disablePadding: false,
    label: 'Cell',
  },
  {
    key: 'visitor_type_id',
    align: 'center',
    disablePadding: true,
    label: 'Type',
  },
  {
    key: 'visitor_status_id',
    align: 'center',
    disablePadding: true,
    label: 'Status',
    render: (visit) => {
      const cellStyles = {
        color: visit.visitor_status_id.toLowerCase() === 'accessed' ? '#04A777' : 'inherit',
      };
      return <TableCell style={cellStyles} align="right">{visit.visitor_status_id}</TableCell>;
    },
  },
  {
    key: 'subdivision',
    align: 'left',
    disablePadding: false,
    label: 'Subdivision',
  },

  {
    key: 'house_num',
    align: 'left',
    disablePadding: false,
    label: 'Lot #',
  },
  {
    key: 'address_1',
    align: 'right',
    disablePadding: false,
    label: 'Full Street Address',
  },
  {
    key: 'platform',
    align: 'center',
    disablePadding: true,
    label: 'Platform',
  },
  {
    key: 'attribution',
    align: 'center',
    disablePadding: true,
    label: 'Attribution',
  },
  {
    key: 'modified_on',
    align: 'right',
    disablePadding: true,
    label: 'Visit Date',
    prepareData: ({
      modified_on,
      timezoneAbbr,
      timezoneValue,
    }) => formatToESTDate(modified_on, timezoneAbbr, timezoneValue),
  },
];

const Visits: React.FC<Props> = (props) => {
  const {
    classes, visits, visitsTotal, loadVisits, loading, exportVisits, printVisits,
    history,
    exportAllVisits,
    exportAllLoading,
    clearFilterState,
  } = props;
  const {
    visitorType, lastStatus, startDate, endDate,
  } = useSelector(selectVisitsFilters);
  const {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    dispatchVisits,
  } = useVisits(loadVisits);
  const [selected, setSelected] = useState<Visit['_id'][]>([]);
  const selectedLength = selected.length;

  useEffect(() => {
    setSelected([]);
  }, [setSelected, page, rowsPerPage]);

  useEffect(() => () => {
    const pageNameIndex = 1;
    const pathElements = history.location.pathname.split('/');
    const pathname = pathElements[pageNameIndex];
    if (pathElements.length === 3) {
      return;
    }
    if ((pathname !== 'visits')) {
      dispatchVisits('RESET_STATE');
    }
  }, [dispatchVisits, history.location.pathname]);

  useEffect(() => {
    return () => {
      clearFilterState();
    };
  }, [clearFilterState]);

  function handleCustomerVisitSelect(visit: Visit) {
    history.push(`/customers/${visit.customer_id}`);
  }

  function handleExport() {
    if (selectedLength !== 0) {
      exportVisits(selected);
    }
  }

  const exportAll = () => {
    exportAllVisits(history.location.pathname.split('/')[1], 'text/csv', {}, {visitorType, lastStatus, startDate, endDate});
  };

  function handlePrint() {
    if (selectedLength !== 0) {
      printVisits(selected);
    }
  }

  function handleChangeOrder(newOrder: Order, newOrderBy: keyof Visit) {
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    setPage(0);
    dispatchVisits('CHANGE_VISIT', { currentOrder: newOrder, currentOrderBy: newOrderBy, currentPage: 0 });
  }

  function handleChangePage(pageNumber: number) {
    setPage(pageNumber);
    dispatchVisits('CHANGE_VISIT', { currentPage: pageNumber });
  }

  function handleChangeRowsPerPage(rowsPerPageAmount: number) {
    setPage(0);
    setRowsPerPage(rowsPerPageAmount);
    dispatchVisits('CHANGE_VISIT', { currentPage: 0, rowsPerPageAmount });
  }

  const handleSearch = (value: string): void => {
    setSearch(value);
    setPage(0);
    dispatchVisits('CHANGE_VISIT', { currentPage: 0, contextSearch: value });
  };

  function handleSelect(newSelected: any[]) {
    setSelected(newSelected);
  }

  return (
    <>
      <Grid container>
        <Grid item xs>
          <SearchBar
            // listName={VISITS}
            defaultValue={search}
            placeholder={searchBarPlaceholder}
            onChange={handleSearch}
          />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <Toolbar>
          <div>
            <Typography variant="h5">
              Visits
              {selectedLength > 0 ? ` ${selectedLength} selected.` : ''}
            </Typography>
          </div>
          <div className={classes.spacer} />
          {selectedLength > 0 && (
            <div className={classes.toolbarActions}>
              <span>Selected actions: </span>
              <Button
                id="fs-export-visit"
                size="small"
                disabled={selectedLength === 0}
                onClick={handleExport}
              >
                Export
              </Button>
              <Button
                size="small"
                disabled={selectedLength === 0}
                onClick={handlePrint}
              >
                Print
              </Button>
            </div>
          )}
          {exportAllLoading ? (<Spinner size={20} />) : (
            <Button
              id="fs-export-visits"
              size="small"
              onClick={exportAll}
              disabled={visits.length === 0}
            >
              Export All
            </Button>
          )}
        </Toolbar>
        <div className={classes.tableWrapper}>
          {loading && (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          )}
          <Table<Visit>
            className={classes.table}
            IDKey="_id"
            selected={selected}
            data={visits}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            header={headRows}
            onSelect={handleSelect}
            onChangeOrder={handleChangeOrder}
            onRowClick={handleCustomerVisitSelect}
          />
        </div>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          rowsCount={visits.length}
          rowsTotal={visitsTotal}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default React.memo(Visits);
