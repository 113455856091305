import { ErrorMessage, ValueOf } from '../../types/main';
import * as VisitsActions from '../actions/visits';

export const VISITS_REQUEST = 'VISITS_REQUEST';
export const VISITS_REQUEST_SUCCESS = 'VISITS_REQUEST_SUCCESS';
export const VISITS_REQUEST_FAILED = 'VISITS_REQUEST_FAILED';

export const VISITS_REPORT_REQUEST = 'VISITS_REPORT_REQUEST';
export const VISITS_REPORT_REQUEST_SUCCESS = 'VISITS_REPORT_REQUEST_SUCCESS';
export const VISITS_REPORT_REQUEST_FAILED = 'VISITS_REPORT_REQUEST_FAILED';

export const VISITS_EXPORT_REQUEST = 'VISITS_EXPORT_REQUEST';
export const VISITS_EXPORT_REQUEST_SUCCESS = 'VISITS_EXPORT_REQUEST_SUCCESS';
export const VISITS_EXPORT_REQUEST_FAILED = 'VISITS_EXPORT_REQUEST_FAILED';

export const VISITS_REPORT_EXPORT_REQUEST = 'VISITS_REPORT_EXPORT_REQUEST';
export const VISITS_REPORT_EXPORT_REQUEST_SUCCESS = 'VISITS_REPORT_EXPORT_REQUEST_SUCCESS';
export const VISITS_REPORT_EXPORT_REQUEST_FAILED = 'VISITS_REPORT_EXPORT_REQUEST_FAILED';

export const EXPORT_ALL_REQUEST = 'EXPORT_ALL_REQUEST';
export const EXPORT_ALL_REQUEST_SUCCESS = 'EXPORT_ALL_REQUEST_SUCCESS';
export const EXPORT_ALL_REQUEST_FAILED = 'EXPORT_ALL_REQUEST_FAILED';

export const VISITS_EXPORT_AND_PRINT_REQUEST = 'VISITS_EXPORT_AND_PRINT_REQUEST';

export const VISITS_REPORT_EXPORT_AND_PRINT_REQUEST = 'VISITS_REPORT_EXPORT_AND_PRINT_REQUEST';


export type VisitsExportRequestActionType =
  | ReturnType<typeof VisitsActions.visitsExportRequest | typeof VisitsActions.visitsExportAndPrintRequest>;

export type VisitsReportExportRequestActionType =
  | ReturnType<typeof VisitsActions.visitsReportExportRequest | typeof VisitsActions.visitsReportExportAndPrintRequest>;

export type VisitsActionsTypes = ReturnType<ValueOf<typeof VisitsActions>>;

export interface Visit {
  _id: number
  subdivision: string
  house_num: string
  address_1: string
  lot_num: string
  customer_id: number
  firstname: string
  lastname: string
  visitor_status_id: string
  visitor_type_id: string
  cell_phone_1: string
  modified_on: string
  property_id: number
  visit_status_id: string
  platform: string
  timezoneAbbr: number
  timezoneValue?: string
  attribution: string
}

export interface VisitReport {
  community: string
  visits: number
  webTours: number
  appTours: number
  callTours: number
  missedTours: number
  propertiesPerCustomer: number
  topTourDayOfWeek: string
  topTourTime: string
}

export interface VisitsReportsRequestSuccess {
  visitsReport: VisitReport[]
  total: number
}

export interface VisitsRequestSuccess {
  items: Visit[]
  total: number
}

export interface VisitsState {
  items: Visit[]
  visitsReport: VisitReport[]
  total: number
  loading: boolean
  error: ErrorMessage['message']
}
